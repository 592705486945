import React, {useState, useEffect} from 'react';
import "./File.css";
import { Image } from 'lucide-react';
import { getImageComponent } from '../../services/storage/storageService'; // Assurez-vous que le chemin est correct
import { useSelector } from 'react-redux';
import { getFileIcon, formatAncre, getFileExtension, getFileColor } from '../../utils';

const FileEditComponent = ({ container }) => { 
    const [file, setFile] = useState(null);
    const email = useSelector((state) => state.user.email);

    useEffect(() => {
        const fetchMedia = async () => {
            if (container) {
                try {
                    // Récupération de l'URL du fichier depuis Firestore
                    const url = await getImageComponent(email, 'File', container.id);

                    // Téléchargement du fichier en tant que Blob
                    const response = await fetch(url);
                    const blob = await response.blob();

                    // Transformation du Blob en objet File
                    const fileObject = new File([blob], `${formatAncre(container.title)}${getFileExtension(blob)}`, { type: blob.type });
                    setFile(fileObject); // Mise à jour de l'état avec l'objet File
                } catch (error) {
                    console.error('Erreur lors de la récupération du média:', error);
                    setFile(null);
                }
            }
        };

        fetchMedia();
    }, [container, email]);

    return (
        <div className='flex items-center'>
            {file ? (
                <img src={getFileIcon(file)} alt="content" style={{backgroundColor: getFileColor(file)}} className='mx-2 w-16 h-16 object-cover object-center rounded-lg' />
            ) : (
                <Image className='mx-2 w-16 h-16' />
            )}
            <div className='flex flex-col'>
                <strong className='titleOverflow mr-2 text-sm truncate'>{container.title}</strong>
                <p className='titleOverflow mr-2 text-sm truncate'>{container.subtitle}</p>
            </div>
        </div>
    );
};

export default FileEditComponent;
