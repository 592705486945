import React from 'react'
import styles from './Title.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function Title(props) {
    const {hobspace} = useHobspace();
 
    return (
        <p className={`${styles.Title} text-3xl max-md:text-2xl max-350:text-xl`} id={props.id} style={{color: hobspace.colorFont, marginBottom: !props.marginBottom ? "10px" : ""}}>
            {props.title}
        </p>
    );
}

export default Title;