import React, {useState} from 'react'
import './Settings.css';

function Settings(props) {

    return (
        <div className='Settings'>
            
        </div>
    );
}


export default Settings;