import React from 'react'
import styles from './ReseauxSociaux.module.css';
import ReseauSocial from '../ReseauSocial/ReseauSocial';
import QRCodeButton from '../QRCodeButton/QRCodeButton';



function ReseauxSociaux(props) {
 
    return (
        <div className={styles.ReseauxSociaux}>
            {props.socialNetworks && props.socialNetworks.map((network, index) => (
                network.name === "qrcode" ?
                    <QRCodeButton img={network.icon} key={index} email={props.email} pp={props.image}/>
                :
                    <ReseauSocial key={index} img={network.icon} link={network.link} />
            ))}
        </div>
    );
}

export default ReseauxSociaux;