export function hexToRgb(hex, opacity=1) {
    hex = hex.replace(/^#/, '');

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b},${opacity})`;
}

export function rgbToHex(rgb) {
    const rgbValues = rgb.split(',');

    const r = parseInt(rgbValues[0].trim()).toString(16).padStart(2, '0');
    const g = parseInt(rgbValues[1].trim()).toString(16).padStart(2, '0');
    const b = parseInt(rgbValues[2].trim()).toString(16).padStart(2, '0');

    const hex = `#${r}${g}${b}`;
    return hex;
}

export function isHexColor(color) {
    const hexColorPattern = /^#([0-9A-Fa-f]{3}){1,2}$/;
    return hexColorPattern.test(color);
}

export function checkBackgroundBrightness(appBackgroundColor, componentColor, threshold = 40) {
    const appBgMatch = appBackgroundColor.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
    if (!appBgMatch) {
        console.error('Format de couleur de fond de l\'application invalide.');
        return componentColor; 
    }

    const appRed = parseInt(appBgMatch[1], 16);
    const appGreen = parseInt(appBgMatch[2], 16);
    const appBlue = parseInt(appBgMatch[3], 16);

    const appBrightness = (appRed * 299 + appGreen * 587 + appBlue * 114) / 1000;

    if (appBrightness < threshold) {
        return false;
    }

    return true;
}

export function getComplementaryColor(hex) {
    // Vérifie si le format hex est correct
    if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
      throw new Error('Couleur hexadécimale invalide');
    }
  
    // Supprime le '#' du début
    hex = hex.substring(1);
  
    // Si c'est une couleur hex courte (3 caractères), la convertit en format long (6 caractères)
    if (hex.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
    }
  
    // Convertit la couleur hex en nombre entier
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    // Calcule la couleur complémentaire
    const compR = (255 - r).toString(16).padStart(2, '0');
    const compG = (255 - g).toString(16).padStart(2, '0');
    const compB = (255 - b).toString(16).padStart(2, '0');
  
    // Retourne la couleur complémentaire en format hex
    return `#${compR}${compG}${compB}`;
}

export function changeBodyBackgroundColor(color) {
    document.body.style.backgroundColor = color;
}
