import React, {useState} from 'react'
import './Tutorial.css';

function Tutorial(props) {

    return (
        <div className='Tutorial'>
            
        </div>
    );
}


export default Tutorial;