import { Link, Hash, Images, Youtube, TicketCheck, Puzzle, Store } from "lucide-react"

export const componentsSpaceLock = [
    {name: "Profile", vertical: true, logo: false},
]
// pour Account ne pas oublier de faire se connecter aux réseaux si pas fait
export const componentsSpace = [
    {id: "", name: "Link", color: '#ff8500', title: "Titre de la section", subtitle: "Sous-titre du lien", link: "https://example.com", visibility: true,},
    {id: "", name: "Links", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "Files", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "File", color: '#ff8500', title: "Titre de la section", subtitle: "Sous-titre du lien", visibility: true,},
    {id: "", name: "Gallery", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "Media", color: '#ff8500', link: "", description: "Titre du media", type: "image", visibility: true,},
    {id: "", name: "Product", color: '#ff8500', title: "Titre de la section", visibility: true,},
    {id: "", name: "Music", color: '#ff8500', title: "Titre de la Section", visibility: true,},
    {id: "", name: "Podcast", color: '#ff8500', title: "Titre de la section", visibility: true,},
    {id: "", name: "Video", color: '#ff8500', idVideo: "", description: true, visibility: true,},
    {id: "", name: "IndividualVideo", color: '#ff8500', title: "Titre de la Section", components: [], visibility: true,},
    {id: "", name: "VideoCollection", color: '#ff8500', title: "Titre de la Section", nbVideo: 3, visibility: true,},
    {id: "", name: "YoutubeShort", color: '#ff8500', title: "Titre de la Section", visibility: true,},
    {id: "", name: "TikTok", color: '#ff8500', title: "Titre de la section", visibility: true,},
    {id: "", name: "Text", color: '#ff8500', title: "Titre de la section", description: "Ajouter du texte.", visibility: true,},
    {id: "", name: "LinkedinAccount", color: '#ff8500', title: "Titre de la section", description: "Décrivez votre poste/activité que vous pratiquez.", visibility: true,},
    {id: "", name: "LinkConcert", color: '#ff8500', link: "https://example.com", title: "Nom de l'évènement", where: "Lieu", type: true, mounth: "Mois", day: "1", year: "2024", visibility: false,},
    {id: "", name: "Event", color: '#ff8500', title: "Titre de la section", components: [], visibility: true,}
];


export const mainComponentsSpace = [
    {id: "", name: "Link", color: '#ff8500', icon: <Link/>, title: "Titre de la section", subtitle: "Sous-titre du lien", link: "https://example.com", image: "", active: false,},
    {id: "", name: "Links", icon: <Link/>, title: "Titre de la section", components: [], visibility: true,},
    {id: "", name: "Account", color: '#ff8500', icon: <Store/> , title: "Titre de la section", profil: true, ytbVideo: false, active: false,},
    {id: "", name: "SliderVideos", color: '#ff8500', icon: <Images/>, title: "Titre de la section", active: false,},
    {id: "", name: "Video", color: '#ff8500', icon: <Youtube/>, title: "Titre de la Section", idVideo: "", description: false, active: false,},
    {id: "", name: "SliderColumn", color: '#ff8500', icon: <TicketCheck/>, title: "Titre de la section", type: false, column: false, active: false,components: []},
    {id: "", name: "WallLinks", color: '#ff8500', icon: <Puzzle/>, title: "Titre de la section", active: false, componentTickets: [], componentArticles: []}
];

export const underComponentsSpace = [
    {id: "", name: "Article", color: '#FFDA24', icon: "fa-solid fa-cart-shopping", title: "Titre de l'article", price: "9,99", link: "", image: "", active: false,},
    {id: "", name: "Link", color: '#00AFDB', icon: "fa-solid fa-link", title: "Titre de la section", subtitle: "Sous-titre du lien", link: "", image: "", active: false,},
    {id: "", name: "TicketConcert", color: '#4C00A8', icon: "fa-solid fa-ticket", link: "", title: "Nom de l'évènement", where: "Lieu", ticket: true, mounth: "Mois", day: "1", year: "2024", active: false,},
]