import React, { useState, useEffect } from 'react'
import './Text.css';
import Title from '../Title/Title';
import { useSelector } from 'react-redux';

function Text(props) {
    const color = useSelector((state) => state.user.colorFont);
 
    return (
        <div className='Text'>
            {props.title &&
                <Title id={props.ancre} title={props.title} marginBottom={true} />
            }
            <p className='text-justify text-xs' style={{color: color}}>
                {props.description}
            </p>
        </div>
    );
}

export default Text;