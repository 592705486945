import React, { useState, useRef, useEffect } from 'react';
import QRCodeStyling from 'qr-code-styling';
import styles from './QrCodeGenerator.module.css';
import "../DashboardPages.css";
import Profil from '../../Profil/Profil';
import { Download } from "lucide-react";
import InputFieldText from '../../../components/InputField/InputFieldText';
import html2canvas from "html2canvas";

function QrCodeGenerator() {
    const qrCodeRef = useRef(null);
    const qrCodeInstance = useRef(null);
    const qrCodeStyle = useRef(null);

    const [styleQrCode, setStyleQrCode] = useState({
        data: "https://example.com",
        dotColor: "#000000",
        dotStyle: "square",
        squareColor: "#000000",
        squareStyle: "square",
        bgColor: "#ffffff",
        logo: undefined,
    });

    const [styleText, setStyleText] = useState({
        text: "Scanner moi !",
        radius: 10,
        bgColor: "#000000",
        color: "#FFFFFF",
    });

    // Initialisation du QR code
    useEffect(() => {
        qrCodeInstance.current = new QRCodeStyling({
            width: 200,
            height: 200,
            data: styleQrCode.data !== "" ? styleQrCode.data : "https://example.com",
            dotsOptions: {
                color: styleQrCode.dotColor,
                type: styleQrCode.dotStyle,
            },
            cornersSquareOptions: {
                color: styleQrCode.squareColor,
                type: styleQrCode.squareStyle,
            },
            cornersDotOptions: { // Configuration des points à l'intérieur des carrés
                color: styleQrCode.squareColor, // Même couleur que les carrés ou différente
                type: styleQrCode.squareStyle, // Type rond pour les points
            },
            backgroundOptions: {
                color: styleQrCode.bgColor,
            },
            imageOptions: {
                crossOrigin: 'anonymous',
                margin: 10,
            },
            image: styleQrCode.logo,
        });
        if (qrCodeRef.current) {
            qrCodeInstance.current.append(qrCodeRef.current);
        }
    }, []);

    // Mise à jour du QR code
    const handleGenerate = () => {
        qrCodeInstance.current.update({
            data: styleQrCode.data !== "" ? styleQrCode.data : "https://example.com",
            dotsOptions: {
                color: styleQrCode.dotColor,
                type: styleQrCode.dotStyle,
            },
            cornersSquareOptions: {
                color: styleQrCode.squareColor,
                type: styleQrCode.squareStyle,
            },
            cornersDotOptions: {
                color: styleQrCode.squareColor,
                type: styleQrCode.squareStyle,
            },
            backgroundOptions: {
                color: styleQrCode.bgColor,
            },
            image: styleQrCode.logo,
        });
    };  

    const handleInputChange = (key, value) => {
        setStyleQrCode((prev) => ({ ...prev, [key]: value }));
    };

    const handleLogoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setStyleQrCode((prev) => ({ ...prev, logo: event.target.result }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDownloadQRAsPNG = () => {
        try {
            // Récupérer le conteneur du QR code
            const qrCodeContainer = qrCodeRef.current;
    
            if (!qrCodeContainer) {
                console.error("Le conteneur du QR code n'a pas pu être trouvé.");
                return;
            }
    
            // Trouver le canvas ou le DOM du QR code
            const svgElement = qrCodeContainer.querySelector("svg");
            const canvasElement = qrCodeContainer.querySelector("canvas");
    
            if (svgElement) {
                // Si c'est un SVG, convertir en image PNG
                const svgData = new XMLSerializer().serializeToString(svgElement);
                const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
                const url = URL.createObjectURL(svgBlob);
    
                const img = new Image();
                img.crossOrigin = "anonymous"; // Pour éviter les problèmes de CORS
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    canvas.width = svgElement.clientWidth;
                    canvas.height = svgElement.clientHeight;
    
                    const ctx = canvas.getContext("2d");
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, 0, 0);
    
                    // Télécharger le PNG généré
                    const link = document.createElement("a");
                    link.href = canvas.toDataURL("image/png");
                    link.download = "qr-code.png";
                    link.click();
    
                    URL.revokeObjectURL(url); // Libérer la mémoire
                };
                img.src = url;
            } else if (canvasElement) {
                // Si c'est un canvas, directement générer le PNG
                const link = document.createElement("a");
                link.href = canvasElement.toDataURL("image/png");
                link.download = "qr-code.png";
                link.click();
            } else {
                console.error("Aucun élément valide pour le QR code n'a été trouvé.");
            }
        } catch (error) {
            console.error("Erreur lors du téléchargement du QR code :", error);
        }
    };
     
    const handleDownloadStyledQRAsPNG = async () => {
        try {
            // Récupérer l'élément HTML à capturer
            const qrCodeElement = qrCodeStyle.current;
    
            if (!qrCodeElement) {
                console.error("L'élément contenant le QR code n'a pas pu être trouvé.");
                return;
            }
    
            // Capturer l'élément HTML en canvas
            const canvas = await html2canvas(qrCodeElement, {
                backgroundColor: null, // Maintenir la transparence si nécessaire
                useCORS: true, // Gérer les problèmes de cross-origin
            });
    
            // Convertir le canvas en image PNG
            const imageDataUrl = canvas.toDataURL("image/png");
    
            // Télécharger l'image
            const link = document.createElement("a");
            link.href = imageDataUrl;
            link.download = "qr-code.png"; // Nom du fichier téléchargé
            link.click();
        } catch (error) {
            console.error("Erreur lors du téléchargement du QR code stylisé :", error);
        }
    };

    useEffect(() => {
        if (qrCodeRef.current) {
            // Vider le conteneur QR code avant d'y ajouter un nouveau QR code
            qrCodeRef.current.innerHTML = "";
            // Ajouter le QR code dans le bon conteneur
            qrCodeInstance.current.append(qrCodeRef.current);
        }
    }, [styleQrCode]);
    

    return (
        <div className="DashboardPages">
            <Profil />
            <div className={styles.sectionQrCodeGenerator}>
                <div className={styles.customQrCode}>
                    <p className='font-bold text-black w-full text-left mb-2 text-xl mt-3'>Style du QR Code</p>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-neutral-100 px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-solid fa-link" style={{color: "#ff8500"}}></i>
                            <p className='text-black'>Changez le lien</p>
                        </div>
                        <InputFieldText 
                            value={styleQrCode.data} 
                            maxLength={2048}
                            onChange={(e) => handleInputChange("data", e.target.value)} 
                            placeholder={styleQrCode.data} 
                            title={""} 
                            type={true}
                        />
                    </div>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-neutral-100 px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-solid fa-circle" style={{color: "#ff8500"}}></i>
                            <p className='text-black'>Changez le style des points</p>
                        </div>
                        <div className='flex flex-row items-center justify-start gap-4'>
                            <button onClick={() => handleInputChange("dotStyle", "rounded")} className={`flex items-center ${styleQrCode.dotStyle === "rounded" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-white`} style={{border: styleQrCode.dotStyle === "rounded" ? "2px #ff8500 solid" : "2px #d1d5db solid"}}>
                                <div className='w-6 h-6 rounded-md bg-black'></div>
                            </button>
                            <button onClick={() => handleInputChange("dotStyle", "square")} className={`flex items-center ${styleQrCode.dotStyle === "square" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-white`} style={{border: styleQrCode.dotStyle === "square" ? "2px #ff8500 solid" : "2px #d1d5db solid"}}>
                                <div className='w-6 h-6 bg-black'></div>
                            </button>
                            <button onClick={() => handleInputChange("dotStyle", "dots")} className={`flex items-center ${styleQrCode.dotStyle === "dots" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-white`} style={{border: styleQrCode.dotStyle === "dots" ? "2px #ff8500 solid" : "2px #d1d5db solid"}}>
                                <div className='w-6 h-6 rounded-full bg-black'></div>
                            </button>
                            <button onClick={() => document.getElementById('dotColorInput').click()} className={`flex items-center w-11 h-11 justify-center rounded-lg relative`} style={{border: "2px #d1d5db solid", backgroundColor: styleQrCode.dotColor}}>
                                <input
                                    type="color"
                                    id="dotColorInput"
                                    value={styleQrCode.dotColor} // La valeur actuelle de la couleur
                                    onChange={() => handleInputChange("dotColor", document.getElementById('dotColorInput').value)}
                                    className="sr-only"
                                />
                                <i className="fa-solid fa-palette absolute" style={{color: styleQrCode.bgColor, fontSize: "20px", transform: "rotate(-45deg)"}}></i>
                            </button>

                        </div>
                    </div>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-neutral-100 px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-regular fa-square" style={{color: "#ff8500"}}></i>
                            <p className='text-black'>Changez le style des carrés</p>
                        </div>
                        <div className='flex flex-row items-center justify-start gap-4'>
                            <button onClick={() => handleInputChange("squareStyle", "extra-rounded")} className={`flex items-center ${styleQrCode.squareStyle === "extra-rounded" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-white`} style={{border: styleQrCode.squareStyle === "extra-rounded" ? "2px #ff8500 solid" : "2px #d1d5db solid"}}>
                                <div className='w-6 h-6 rounded-md' style={{border: "6px black solid"}}></div>
                            </button>
                            <button onClick={() => handleInputChange("squareStyle", "square")} className={`flex items-center ${styleQrCode.squareStyle === "square" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-white`} style={{border: styleQrCode.squareStyle === "square" ? "2px #ff8500 solid" : "2px #d1d5db solid"}}>
                                <div className='w-6 h-6' style={{border: "6px black solid"}}></div>
                            </button>
                            <button onClick={() => handleInputChange("squareStyle", "dot")} className={`flex items-center ${styleQrCode.squareStyle === "dot" ? "w-12 h-12" : "w-11 h-11"} justify-center rounded-lg bg-white`} style={{border: styleQrCode.squareStyle === "dot" ? "2px #ff8500 solid" : "2px #d1d5db solid"}}>
                                <div className='w-6 h-6 rounded-full' style={{border: "6px black solid"}}></div>
                            </button>
                            <button onClick={() => document.getElementById('squareColorInput').click()} className={`flex items-center w-11 h-11 justify-center rounded-lg relative`} style={{border: "2px #d1d5db solid", backgroundColor: styleQrCode.squareColor}}>
                                <input
                                    type="color"
                                    id="squareColorInput"
                                    value={styleQrCode.squareColor} // La valeur actuelle de la couleur
                                    onChange={() => handleInputChange("squareColor", document.getElementById('squareColorInput').value)}
                                    className="sr-only"
                                />
                                <i className="fa-solid fa-palette absolute" style={{color: styleQrCode.bgColor, fontSize: "20px", transform: "rotate(-45deg)"}}></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-neutral-100 px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-solid fa-image" style={{color: "#ff8500"}}></i>
                            <p className='text-black'>Changez la couleur de fond et ajoutez une image</p>
                        </div>
                        <div className='flex flex-row items-center justify-start gap-4'>
                            <button onClick={() => document.getElementById('bgColorInput').click()} className={`flex items-center w-11 h-11 justify-center rounded-lg relative`} style={{border: "2px #d1d5db solid", backgroundColor: styleQrCode.bgColor}}>
                                <input
                                    type="color"
                                    id="bgColorInput"
                                    value={styleQrCode.bgColor} // La valeur actuelle de la couleur
                                    onChange={() => handleInputChange("bgColor", document.getElementById('bgColorInput').value)}
                                    className="sr-only"
                                />
                                <i className="fa-solid fa-fill-drip absolute" style={{color: styleQrCode.dotColor, fontSize: "20px"}}></i>
                            </button>
                            <button
                                onClick={() => document.getElementById('logoInput').click()}
                                className={`flex items-center w-11 h-11 justify-center rounded-lg relative`}
                                style={{
                                    border: "2px #d1d5db solid",
                                    backgroundColor: "#ffffff",
                                }}
                            >
                                <input
                                    type="file"
                                    id="logoInput"
                                    accept="image/*"
                                    onChange={handleLogoUpload}
                                    className="sr-only"
                                />
                                {styleQrCode.logo ? (
                                    <img
                                        src={styleQrCode.logo}
                                        alt="Logo Preview"
                                        className="absolute inset-0 object-cover w-full h-full rounded-lg"
                                    />
                                ) : (
                                    <i
                                        className="fa-solid fa-image absolute"
                                        style={{
                                            color: "#000000",
                                            fontSize: "20px",
                                        }}
                                    ></i>
                                )}
                            </button>
                        </div>
                    </div>
                    
                    <p className='font-bold text-black w-full text-left mb-2 text-xl mt-3'>Style du texte</p>
                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-neutral-100 px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-solid fa-font" style={{color: "#ff8500"}}></i>
                            <p className='text-black'>Personnalisez le texte</p>
                        </div>
                        <InputFieldText 
                            value={styleText.text} 
                            maxLength={50}
                            onChange={(e) => setStyleText((prev) => ({ ...prev, text: e.target.value }))}
                            placeholder={styleText.text} 
                            title={""} 
                            type={true}
                        />
                    </div>

                    <div className='flex flex-col items-start justify-center rounded-lg gap-1 bg-neutral-100 px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-solid fa-paint-roller" style={{color: "#ff8500"}}></i>
                            <p className='text-black'>Changez la couleur du texte et de fond</p>
                        </div>
                        <div className='flex flex-row items-center justify-start gap-4'>
                            <button onClick={() => document.getElementById('textColorInput').click()} className={`flex items-center w-11 h-11 justify-center rounded-lg relative`} style={{border: "2px #d1d5db solid", backgroundColor: styleText.bgColor}}>
                                <input
                                    type="color"
                                    id="textColorInput"
                                    value={styleText.color} // La valeur actuelle de la couleur
                                    onChange={(e) => setStyleText((prev) => ({ ...prev, color: document.getElementById('textColorInput').value}))}
                                    className="sr-only"
                                />
                                <i className="fa-solid fa-font absolute" style={{color: styleText.color, fontSize: "20px"}}></i>
                            </button>
                            <button onClick={() => document.getElementById('textBgColorInput').click()} className={`flex items-center w-11 h-11 justify-center rounded-lg relative`} style={{border: "2px #d1d5db solid", backgroundColor: styleText.color}}>
                                <input
                                    type="color"
                                    id="textBgColorInput"
                                    value={styleText.bgColor} // La valeur actuelle de la couleur
                                    onChange={(e) => setStyleText((prev) => ({ ...prev, color: document.getElementById('textBgColorInput').value}))}
                                    className="sr-only"
                                />
                                <i className="fa-solid fa-fill-drip absolute" style={{color: styleText.bgColor, fontSize: "20px"}}></i>
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-col mb-4 items-start justify-center rounded-lg gap-1 bg-neutral-100 px-3 py-2 w-full'>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <i className="fa-regular fa-circle-dot" style={{color: "#ff8500"}}></i>
                            <p className='text-black'>Changez le rayon des bords</p>
                        </div>
                        <div class="PB-range-slider-div">
                            <input type="range" min="0" max="50" value={styleText.radius} onChange={(e) => setStyleText((prev) => ({ ...prev, radius: e.target.value }))} class="PB-range-slider" id="myRange"/>
                            <p class="PB-range-slidervalue">{styleText.radius}px</p>
                        </div>
                    </div>


                </div>
                <div className={styles.previewQrCode}>
                    <div className="flex flex-col items-center justify-center gap-5" ref={qrCodeStyle}>
                        <div className="qr-code-preview rounded-3xl p-6" style={{ backgroundColor: styleQrCode.bgColor }} ref={qrCodeRef}></div>

                        <p
                            className="py-2 px-4"
                            style={{
                                borderRadius: `${styleText.radius}px`,
                                backgroundColor: styleText.bgColor,
                                color: styleText.color,
                            }}
                        >
                            {styleText.text}
                        </p>
                    </div>
                    <div style={{ backgroundColor: "#f4f5ff", width: "calc(100% + 20px", height: "2px" }}></div>
                    <button
                        className="px-4 py-2 rounded-lg text-white gap-2 flex flex-row items-center justify-center"
                        style={{ backgroundColor: "#ff8500" }}
                        onClick={handleDownloadStyledQRAsPNG}
                    >
                        Télécharger le QR code
                        <Download color={"#fff"} size={20} />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default QrCodeGenerator;
