import React, {useState, useEffect} from 'react'
import './Login.css';
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Google from "../../assets/google.png";
import Apple from "../../assets/apple.png";
import '@fortawesome/fontawesome-free/css/all.css';
import { loginWithEmailOrHobsSpace } from '../../services/auth/authService';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import { emailIsValid, hobsSpaceIsValid, passwordIsValid } from '../../services/store/reducers/authSlice';

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const error = useSelector((state) => state.auth.passwordIsValid);
    const errorEmail = useSelector((state) => state.auth.emailIsValid);
    const errorMessage = useSelector((state) => state.auth.errorMessage);
    const errorHobs = useSelector((state) => state.auth.hobsSpaceIsValid);
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        loginWithEmailOrHobsSpace(email, password, dispatch, navigate);
    };

    useEffect(() => {
       dispatch(passwordIsValid({isValid: null, message: ""}));
       dispatch(emailIsValid({isValid: null, message: ""}));
       dispatch(hobsSpaceIsValid({isValid: null, message: ""}));
    }, []);
 
    return (
        <div className='Login'>
            <TitleTabNavigator title={"Connexion"}/>
            <Link className='containerLogoLogin' to='/'><img className='logoLogin' src={Logo} alt="logo hobs space retour page d'accueil"/></Link>
            <div className='sectionLogin'>
                <div className='containerLogin'>
                    <div className='containerTitleLogin'>
                        <h1 className='titleLogin'>Bienvenue</h1>
                        <p className='subtitleLogin'>Connectez-vous à votre Hob's Space</p>
                    </div>
                    <div className="login-container">
                        <form onSubmit={handleSubmit} className="login-form">
                            <div className="form-group-login user">
                                <input type="text" placeholder="Nom d'utilisateur / Hob's Space" id="username" name="username" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                {errorEmail === false &&
                                    <ErrorMessage message={errorMessage} error={!errorEmail} />
                                }
                            </div>
                            <div className="form-group-login mt-16">
                                <button className='buttonEyesPassword' onClick={() => setPasswordIsVisible(!passwordIsVisible)}>
                                    {passwordIsVisible ? (
                                        <i className="fa-solid fa-eye"></i>
                                    ):(
                                        <i className="fa-solid fa-eye-slash"></i>
                                    )}
                                </button>
                                <input type={passwordIsVisible ? "text" : "password"} placeholder='Mot de passe' id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                {error === false && 
                                    <ErrorMessage message={errorMessage} error={!error} />
                                }
                            </div>
                            <div className="forgot-password mt-10">
                            <a href="#">Mot de passe oublié ?</a>
                            </div>
                            <button type="submit" className='buttonSubmitLogin'>Se connecter</button>
                        </form>
                        <div className='ligneLogin'></div>
                        {/* <div className="divider">
                            <span>OU</span>
                        </div>
                        <div className="social-login">
                            <button className="google-btn"><img className='iconSocialLoginBtn' src={Google} alt='logo google connexion'/>Se connecter avec Google</button>
                            <button className="apple-btn"><img className='iconSocialLoginBtn' src={Apple} alt='logo apple connexion'/>Se connecter avec Apple</button>
                        </div> */}
                        <div className="signup-link">
                            <p>Vous n'avez pas de compte ? <Link to='/signup' className='signupLogin'>Inscrivez-vous</Link></p>
                        </div>
                        <div className="signup-link signupTerm">
                            <p>Ce site est protégé par reCAPTCHA et les <Link to='' className='signupLogin'>règles de confidentialité</Link> et les <Link to='' className='signupLogin'>conditions d'utilisation de Google</Link> s'appliquent.</p>
                        </div>
                    </div>
                </div>
                   
            </div>
            <div className='containerImgLogin'>
                    <div className='backgroundImgLogin'></div>
                    
            </div> 
         </div>
    );
}

export default Login;