import React, { useState } from 'react';
import './ButtonEditImage.css';
import '@fortawesome/fontawesome-free/css/all.css';
import CustomModal from '../Modal/CustomModal/CustomModal';
import { uploadImage } from '../../services/storage/storageService';
import { useUser } from "../../utils/contexts/userContext";
import { useSelector } from "react-redux";

const ButtonEditImageComponent = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(undefined);
    const [imageSrc, setImageSrc] = useState(undefined);
    const { user } = useUser();
    const email = useSelector((state) => state.user.email);
    const [error, setError] = useState(false);
    const [isUploading, setIsUploading] = useState(false); // State to manage the upload process

    const handleButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFile(undefined);
        setImageSrc(undefined);
        setError(false);
        setIsUploading(false); // Reset the uploading state when modal is closed
    };

    const handleFileSelected = (files) => {
        const selectedFile = files[0];

        if (validateFile(selectedFile)) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
                props.setImage(reader.result);
                setFile(selectedFile);
            };
            reader.readAsDataURL(selectedFile);
            setError(false);
        } else {
            setError(true);
        }
    };

    const validateFile = (file) => {
        return file.type.startsWith('image/') && file.size <= 16 * 1024 * 1024; // 16MB
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleFileSelected(files);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleModalClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (event) => handleFileSelected(event.target.files);
        input.click();
    };

    const uploadFile = async () => {
        if (user && file) {
            setIsUploading(true); // Start uploading
            try {
                const downloadURL = await uploadImage(file, email, props.folder, props.nameFile);
                console.log("Image uploaded:", downloadURL);
                handleCloseModal();
            } catch (error) {
                console.error('Error uploading image:', error);
            } finally {
                setIsUploading(false); // End uploading
            }
        } else {
            setError(true);
        }
    };

    return (
        <>
            <button
                className='border-2 p-2 rounded-lg border-gray-300 w-full focus:outline-none focus:border-gray-300'
                onClick={handleButtonClick}
            >
                <i className="fa-solid fa-arrow-up-from-bracket" style={{ color: "#000", marginRight: "8px", fontSize: "16px" }}></i>
                Sélectionner une image
            </button>
            <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
                <div className='containerSelectImgProfil'>
                    <p className='titleModalSettings'>{props.title}</p>
                    <div
                        className='dropzone'
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={handleModalClick}
                    >
                        {imageSrc !== undefined ? 
                            <img className='imgUploadProfil' src={imageSrc} alt="Aperçu" />
                            :
                            <div className='iconButtonImgProfil'>
                                <i className={"fa-solid fa-cloud-arrow-up"}></i>
                            </div>
                        }
                        <p style={{ color: error ? "red" : "black" }}>
                            {error ? "Fichier invalide ou trop volumineux." : "Déposez une image ici ou cliquez pour sélectionner un fichier"}
                        </p>
                    </div>
                    <div className='containerButtonImgProfil'>
                        <button className='buttonImgProfilClose' onClick={handleCloseModal}>Annuler</button>
                        <button 
                            className='buttonImgProfilSave' 
                            onClick={file !== undefined && !isUploading ? uploadFile : () => setError(true)} 
                            disabled={isUploading} // Disable button while uploading
                        >
                            {isUploading ? (
                                <i className="fa fa-spinner fa-spin"></i> // Loader icon
                            ) : (
                                'Enregistrer'
                            )}
                        </button>
                    </div>
                </div>
            </CustomModal>
        </>
    );
};

export default ButtonEditImageComponent;
