import React, { useState, useEffect } from 'react'
import styles from './Gallery.module.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import Media from '../Media/Media';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function Gallery(props) {
    const {hobspace} = useHobspace();
    const [components, setComponents] = useState([]);

    useEffect(() => {
        const components = props.components.map((c, index) => (
            <Media key={index} id={c.id} title={c.title} description={c.description} link={c.link} type={c.type} email={props.email} />
        ));
        setComponents(components);
    }, [props.components]); 
 
    return (
        <div className={styles.Gallery}>
            {props.title &&
                <Title id={props.ancre} color={hobspace.colorFont} title={props.title} marginBottom={true} />
            }
            {components && 
                <Slider components={components} />   
            }
        </div>
    );
}

export default Gallery;