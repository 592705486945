import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './DragAndDropLinks.css'; 
import YtbColor from "../../assets/youtube-color.png";
import Linkedin from "../../assets/linkedin-color.png";
import X from "../../assets/x.png";
import Tiktok from "../../assets/tiktok-color.png";
import Insta from "../../assets/insta-color.png";
import Facebook from "../../assets/facebook-color.png";
import QRCode from "../../assets/qrcode.png";
import Globe from "../../assets/globe.png";
import Itunes from "../../assets/itunes-color.png";
import Spotify from "../../assets/spotify-color.png";
import Deezer from "../../assets/deezer-color.png";
import Twitch from "../../assets/twitch-color.png";
import ButtonSocialNetwork from '../ButtonSocialNetwork/ButtonSocialNetwork';
import {GripVertical} from "lucide-react"

function DragAndDropLinks({ initialLinks, deleteLink, onReorder, droppableId, }) {
    const [links, setLinks] = useState(initialLinks);

    useEffect(() => {
        setLinks(initialLinks);
    }, [initialLinks]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(links);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setLinks(items);
        if (onReorder) {
            onReorder(items);
        }
    };

    const renderLinkIcon = (name) => {
        switch (name) {
            case "instagram":
                return Insta;
            case "facebook":
                return Facebook;
            case "x":
                return X;
            case "youtube":
                return YtbColor;
            case "tiktok":
                return Tiktok;
            case "spotify":
                return Spotify;
            case "deezer":
                return Deezer;
            case "itunes":
                return Itunes;
            case "linkedin":
                return Linkedin;
            case "site web":
                return Globe;
            case "qrcode":
                return QRCode;
            case "twitch":
                return Twitch;
            default:
                return name;
        }
    }
    
    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId={droppableId}>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="container-list h-auto"
                    >
                        {links && links.map((link, index) => (
                            <Draggable key={link.id} draggableId={link.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={`h-auto bg-neutral-100 draggable-link ${snapshot.isDragging ? 'dragging' : ''}`}
                              >
                                <div className='flex-row flex w-full gap-1 pl-1'>                                  
                                    <div className='flex items-center cursor-grab w-auto' {...provided.dragHandleProps}>
                                        <GripVertical color="#475569" />
                                    </div>
                                    <ButtonSocialNetwork link={link} renderLinkIcon={renderLinkIcon} deleteLink={deleteLink} />
                                  </div>  
                              </div>
                            )}
                          </Draggable>
                          
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default DragAndDropLinks;
