import React, { useEffect } from 'react';
import Modal from 'react-modal';
import "./CustomModal.css";

function CustomModalPreview({children, isOpen, onClose}) {
	useEffect(() => {
		const container = document.getElementById("preview");
		if (isOpen) {
		  container.style.overflow = 'hidden';;
		} else {
		  container.style.overflow = 'auto';;
		}
	}, [isOpen]);

    return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			contentLabel='Exemple de modal'
			className={`modalPreview`}
			style={{
				overlay: {
					backgroundColor: 'rgba(0, 0, 0, 0.7)',
					zIndex: 900,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					inset: "0px",
				},
				content: {
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
				},
			}}
			parentSelector={() => document.querySelector('#preview')}
			bodyOpenClassName=""
		>
		{children}
			
		</Modal>
	)
}

export default CustomModalPreview;

