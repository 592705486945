import React from 'react';
import "./LinkConcert.css";

const LinkConcertEditComponent = ({ container }) => {
    
    return (
        <div className='flex items-center'>
            {container && (
                <>
                    <div className='flex flex-col h-16 w-16 rounded-lg bg-slate-800 mx-2 items-center justify-center'>
                        <p className='text-slate-200 w-full text-center text-xs'>{container.mounth}</p>
                        <p className='text-slate-200 w-full text-center text-xs'>{container.day}</p>
                        <p className='text-slate-200 w-full text-center text-xs'>{container.year}</p>
                    </div>
                    <div className='flex flex-col'>
                        <strong className='titleOverflow mr-2 text-sm truncate'>{container.title}</strong>
                        <p className='titleOverflow mr-2 text-sm truncate'>{container.where}</p>
                        <a href={container.link} className='titleOverflow text-blue-500 text-sm text-left font-bold truncate'>{container.link}</a>
                    </div>
                </>
            )}
        </div>
    );
};

export default LinkConcertEditComponent;
