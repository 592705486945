import React, {useState, useEffect} from 'react'
import './ButtonEditComponent.css';
import '@fortawesome/fontawesome-free/css/all.css';
import CustomModal from '../Modal/CustomModal/CustomModal';
import { useUser } from '../../utils/contexts/userContext';
import { PencilLine } from 'lucide-react';
import MediaEdit from '../Media/MediaEdit';
import LinkConcertEdit from '../LinkConcert/LinkConcertEdit';
import OneVideoEdit from '../OneVideo/OneVideoEdit';
import LinkEdit from '../Link/LinkEdit';
import FileEdit from '../File/FileEdit';
import VideoCollectionEdit from '../VideoCollection/VideoCollectionEdit';

function ButtonEditUnderComponent(props) {
    const [openModal, setOpenModal] = useState(false);
    const {user} = useUser();
    const [objectLink, setObjectLink] = useState();
    const [error, setError] = useState({status: false, message: ""});


    const renderComponent = (obj) => {
        switch (obj.name) {
            case "Link":
                return <LinkEdit obj={obj} handleCloseModal={handleCloseModal} />; 
            case "LinkConcert":
                return <LinkConcertEdit obj={obj} handleCloseModal={handleCloseModal} />;
            case "Video":
                return <OneVideoEdit obj={obj} handleCloseModal={handleCloseModal} />;
            case "VideoCollection":
                return <VideoCollectionEdit obj={obj} handleCloseModal={handleCloseModal} />;
            case "Media":
                return <MediaEdit obj={obj} handleCloseModal={handleCloseModal} />
            case "File":
                return <FileEdit obj={obj} handleCloseModal={handleCloseModal} />; 
            default:
                return null;
        }
    };

    const renderComponentTitle = (obj) => {
        switch (obj.name) {
            case "Link":
                return "le lien redirectif";
            case "LinkConcert":
                return "le lien évènementiel";
            case "Video":
                return "la vidéo youtube";
            case "VideoCollection":
                return "les paramètres";
            case "Media":
                return "les médias";
            case "File":
                return "fichier PDF";
            default:
                return null;
        }
    };

    const handleCloseModal = () => {
        setError({status: false, message: ""});
        setOpenModal(false);
    }

    useEffect(() => {
        function findObjectById(id, data) {
            for (const item of data) {
                if (item.id === id) {
                    setObjectLink(item);
                    console.log(item);
                }
                if (item.components) {
                    const result = findObjectById(id, item.components);
                    if (result) {
                        setObjectLink(result);
                        console.log(result);
                    }
                }
            }
            return null;
        }

        findObjectById(props.obj.id, user.page);
    }, [user.page]);

 
    return (
        <>
            {props.textButton ? 
                <button className='buttonEditUnderComponent flex flex-row p-2 text-xs text-white items-center flex-start bg-neutral-100 rounded-full gap-1' style={{backgroundColor: "#ff8500"}} onClick={() => setOpenModal(true)}>
                    <PencilLine />{props.textButton} 
                </button>
            :
                <button className='buttonDragAndDrop' onClick={() => setOpenModal(true)}>
                    <PencilLine />
                </button>
            }
            <CustomModal isOpen={openModal} onClose={handleCloseModal}>
                {objectLink &&
                    <div className='containerEditComponent'>
                        <div className='containerLinkEditComponent'>
                            <p className='titleEditComponent'>Modifiez {renderComponentTitle(objectLink)}</p>
                        </div>
                            {renderComponent(objectLink)}
                        {error.status && <p className='errorEditComponent'>{error.message}</p>}
                    </div>
                }
            </CustomModal>
        </>
    );
}

export default ButtonEditUnderComponent;