import React from 'react';
import PropTypes from 'prop-types';
import "./CallButton.css";
import { useUser } from "../../utils/contexts/userContext";
import { formatPhoneNumber } from '../../utils';

function CallButton({ phoneNumber }) {
    const { user } = useUser();
    const handleCallClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <button onClick={handleCallClick} className='buttonDisplayEmailPhone'>
            <div className='iconButton'>
                <i style={{color: user.colorFont}} className={"fa-solid fa-mobile-screen-button"}></i>
            </div>
            <p style={{color: user.colorFont}}>{formatPhoneNumber(`+${phoneNumber}`, user.country.code)}</p>
        </button>
    );
}

CallButton.propTypes = {
    phoneNumber: PropTypes.string.isRequired,
    children: PropTypes.node,
};

CallButton.defaultProps = {
    children: null,
};

export default CallButton;
