import React from 'react'
import './ReseauxSociaux.css';
import ReseauSocial from '../ReseauSocial/ReseauSocial';
import QRCodeButton from '../QRCodeButton/QRCodeButton';



function ReseauxSociaux(props) {
 
    return (
        <div className='ReseauxSociaux'>
            {props.socialNetworks && props.socialNetworks.map((network, index) => (
                network.name === "qrcode" ?
                    <QRCodeButton img={network.icon} key={index} />
                :
                    <ReseauSocial key={index} img={network.icon} link={network.link} />
            ))}
        </div>
    );
}

export default ReseauxSociaux;