import React from 'react';
import "./ErrorMessage.css";

const ErrorMessage = (props) => {
  return (
    <p className='textErrorMessage' style={{color: props.error ? "red" : "green"}}>
        {props.message}
    </p>
  );
}

export default ErrorMessage;
