import React, { useEffect, useState } from 'react';
import './SignUp.css';
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Google from "../../assets/google.png";
import Apple from "../../assets/apple.png";
import { uploadImageProfile } from '../../services/storage/storageService';
import { setFirstname, setImages, setLastname } from '../../services/store/reducers/userSlice';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import {useSelector, useDispatch} from "react-redux";
import { isValidString, isValidUrl, links } from '../../utils';
import '@fortawesome/fontawesome-free/css/all.css';
import ButtonEditImage from '../../components/ButtonEditImage/ButtonEditImage';
import InputFieldText from '../../components/InputField/InputFieldText';
import { useUser } from '../../utils/contexts/userContext';

function ConfigProfile({createUser}) {
    const [step, setStep] = useState(1);
    const firstname = useSelector((state) => state.user.firstname);
    const lastname = useSelector((state) => state.user.lastname);
    const hobspace = useSelector((state) => state.user.hobsSpace);
    const offers = useSelector((state) => state.user.offers);
    const [instagram, setInstagram] = useState("");
    const [facebook, setFacebook] = useState("");
    const [website, setWebsite] = useState("");
    const images = useSelector((state) => state.user.images);
    const [error, setError] = useState({message: "", status: false});
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false); 

    async function urlToFile(url, fileName) {
        try {
            const response = await fetch(url);
            const data = await response.blob();
            const mimeType = data.type || "application/octet-stream"; // Récupère le type MIME réel ou définit un type par défaut
            const extension = mimeType.split('/')[1]; // Extrait l'extension à partir du type MIME
            const newFileName = fileName || `example.${extension}`;
            return new File([data], newFileName, { type: mimeType });
        } catch (error) {
            console.error("Erreur lors de la conversion de l'URL en fichier :", error);
        }
    }    

    const handleChangeFirstname = (newValue) => {
        if(isValidString(newValue)) {
            setError({message: "", status: false});
            dispatch(setFirstname(newValue));
        }
        else {
            setError({message: "Le prénom contient des caractères indésirable", status: true});
        }
    };
    const handleChangeLastname = (newValue) => {
        if(isValidString(newValue)) {
            setError({message: "", status: false});
            dispatch(setLastname(newValue));
        }
        else {
            setError({message: "Le nom contient des caractères indésirable", status: true});
        }
    };

    const handleLinkBlur = (id, value) => {
        if (!value) {
            // Si la valeur est vide, on ne fait rien
            setError({ message: "", status: false });
            return;
        }
    
        if (!isValidUrl(value)) {
            setError({ message: `L'url entrée pour ${id} n'est pas valide.`, status: true });
        } else {
            setError({ message: "", status: false });
            switch (id) {
                case "instagram":
                    setInstagram(value);
                    break;
                case "facebook":
                    setFacebook(value);
                    break;
                case "website":
                    setWebsite(value);
                    break;
                default:
                    break;
            }
        }
    };    
    
    const addLink = (name, url) => {
        const linkTemplate = links.find(link => link.name === name);
        if (linkTemplate && url) {
            return { ...linkTemplate, active: true, link: url };
        }
        return null;
    };

    const handleEnd = async() => {   
        const updatedLinks = [
            addLink("instagram", instagram),
            addLink("facebook", facebook),
            addLink("site web", website)
        ].filter(link => link !== null);
        
        const userData = {
            offer: offers,
            nameSpace: hobspace,
            firstname: firstname,
            description: "Entrer une description de 140 caractères maximum",
            lastname: lastname,
            phoneNumber: "",
            country: { code: "FR", name: "France" },
            colorFont: "#FFFFFF",
            backgroundColor: "#000000",
            links: updatedLinks,  // Ajouter les liens actifs ici
            page: [],
            pageInit: [{ name: "Profile", vertical: true, logo: false }],
            subscription: {
                type: "",
                howLong: "",
                createAt: "",
            },
            contactActive: {
                email: false,
                phone: false,
            },
        };
        try {
            setIsLoading(true);
            const file = await urlToFile(images, "example");
            console.log(images);
            dispatch(setImages(images));
            await createUser(userData, file);
        } catch (error) {
            console.error('Error create user', error);
        } finally {
            setIsLoading(false); // Désactiver le loader
        }
    };

    useEffect(() => {
        setIsLoading(false);
        setInstagram("");
        setFacebook("");
        setWebsite("");
        setError({message: "", status: false});
        dispatch(setLastname(""));
        dispatch(setFirstname(""));
    }, [])

    return (
        <div className='Login'>
            <Link className='containerLogoLogin' to='/'><img className='logoLogin' src={Logo} alt="logo hobs space retour page d'accueil"/></Link>
            <div className='sectionConfigProfile'>
                <div className='containerTitleLogin'>
                    <h1 className='titleLogin'>Configurez votre profil</h1>
                    <p className=''>Nous avons juste besoin de quelques éléments pour terminer la mise en place de votre page.</p>
                </div>
                <div className='containerConfigProfile'>
                    
                    <div className='containerStep'>
                        <div className={`step ${step >= 1 ? "active" : ""}`}>
                            <span className={step >= 1 ? "active" : ""}>Profil</span>
                            <p>1</p>
                        </div>
                        <div className={`stepLine ${step >= 2 ? "active" : ""}`}></div>
                        <div className={`step ${step >= 2 ? "active" : ""}`}>
                            <span className={step >= 2 ? "active" : ""}>Liens</span>
                            <p>2</p>
                        </div>
                        <div className={`stepLine ${step >= 3 ? "active" : ""}`}></div>
                        <div className={`step ${step >= 3 ? "active" : ""}`}>
                            <span className={step >= 3 ? "active" : ""}>Contenu</span>
                            <p>3</p>
                        </div>
                    </div>
                    <div className='sectionLayoutConfig'>
                        <div className='containerLayoutConfig'>
                            {images !== undefined ? (
                                <img className='ImgConfigProfil' src={(images)} alt="Aperçu de l'image" />
                            ) : (
                                <div className='ImgConfigProfil'>
                                    <i style={{ color: "#fff" }} className={"fa-regular fa-user"}></i>
                                </div>
                            )}
                            <div className='containerNameConfig'>
                                <p style={{color: lastname !== "" ? "#fff" : "rgba(255,255,255,0.3)"}}>{lastname !== "" ? lastname : "Nom"}</p>
                                <p style={{color: firstname !== "" ? "#fff" : "rgba(255,255,255,0.3)"}}>{firstname !== "" ? firstname : "Prénom"}</p>
                            </div>
                            {step >= 2 && <div className='containerNameConfig'>
                                {instagram !== "" ? <i className={"fa-brands fa-instagram"}></i> : <div className='socialLinkDash'></div>}
                                {facebook !== "" ? <i className={"fa-brands fa-square-facebook"}></i> : <div className='socialLinkDash'></div>}
                                {website !== "" ? <i className={"fa-solid fa-earth-europe"}></i> : <div className='socialLinkDash'></div>}
                            </div>}
                        </div>
                    </div>
                    {step === 1 &&
                        <>
                            <div className='containerTextConfigProfile'>
                                <h1 className='titleLogin text-center'>Ajouter une image d'en-tête et votre nom et prénom</h1>
                                <p className='text-center'>Faites ressortir votre page - cette image ainsi que votre nom et prénom peuvent être modifiée ultérieurement.</p>
                            </div>
                            <div className='containerEditProfilImg'>
                                <ButtonEditImage title={"Charger une photo de profile"} folder={"profile"} nameFile={"profile-image"} />
                            </div>
                            <div className='containerInputFirstnameLastname'>
                                <InputFieldText value={lastname} onChange={(e) => handleChangeLastname(e.target.value)} title={"Nom"} type={true} maxLength={30} />
                                <InputFieldText value={firstname} onChange={(e) => handleChangeFirstname(e.target.value)} title={"Prénom"} type={true} maxLength={30} />
                                {error.status === true && 
                                        <ErrorMessage message={error.message} error={error.status} />
                                }
                            </div>
                            <div className='containerButtonConfigProfile'>
                                <button 
                                    className={`buttonConfigProfile ${((lastname !== "" || firstname !== "") && !error.status) ? "next" : "disable"}`} 
                                    onClick={((lastname !== "" || firstname !== "") && !error.status) ? () => setStep(2) : () => {}}
                                    >
                                    Suivant
                                </button>
                            </div>
                        </>
                    }
                    {step === 2 &&
                        <>
                            <div className='containerTextConfigProfile'>
                                <h1 className='titleLogin'>Entrez les liens de vos réseaux sociaux</h1>
                                <p className=''>Ajoutez des liens sociaux pour que les gens puissent suivre vos comptes - vous pourrez en ajouter plus tard.</p>
                            </div>
                            <div className='containerInputFirstnameLastname'>
                                <div>
                                    <p className='titleInputFieldConfigProfile'>
                                        <i className={"fa-brands fa-instagram"}></i>
                                        Instagram
                                    </p>
                                    <InputFieldText
                                        maxLength={100}
                                        value={instagram}
                                        onBlur={(e) => handleLinkBlur("instagram", e.target.value)}
                                        onChange={(e) => setInstagram(e.target.value)}
                                        placeholder=""
                                        title=""
                                        type={true}
                                    />
                                </div>
                                <div>
                                    <p className='titleInputFieldConfigProfile'>
                                        <i className={"fa-brands fa-square-facebook"}></i>
                                        Facebook
                                    </p>
                                    <InputFieldText
                                        maxLength={100}
                                        value={facebook}
                                        onBlur={(e) => handleLinkBlur("facebook", e.target.value)}
                                        onChange={(e) => setFacebook(e.target.value)}
                                        placeholder=""
                                        title=""
                                        type={true}
                                    />
                                 </div>
                                <div>
                                    <p className='titleInputFieldConfigProfile'>
                                        <i className={"fa-solid fa-earth-europe"}></i>
                                        Site web
                                    </p>
                                    <InputFieldText
                                        maxLength={100}
                                        value={website}
                                        onBlur={(e) => handleLinkBlur("website", e.target.value)}
                                        onChange={(e) => setWebsite(e.target.value)}
                                        placeholder=""
                                        title=""
                                        type={true}
                                    />
                                </div>
                                
                                {error.status === true && 
                                    <ErrorMessage message={error.message} error={error.status} />
                                }
                            </div>
                            <div className='containerButtonConfigProfile'>
                                <button className='buttonConfigProfile' onClick={() => setStep(1)}>Précédent</button>
                                <button 
                                    className={`buttonConfigProfile ${!error.status ? "next" : "disable"}`} 
                                    onClick={!error.status ? () => setStep(3) : () => {}}
                                    >
                                    Suivant
                                </button>
                            </div>
                            <div className='containerButtonConfigProfile skip'>
                                <button className='buttonConfigProfile skip' onClick={() => setStep(3)}>Passer</button>
                            </div>
                        </>
                    }
                    {step === 3 &&
                        <>
                            <div className='containerTextConfigProfile'>
                                <h1 className='titleLogin'>Votre page est presque terminée</h1>
                                <p className=''>Continuez à personnaliser votre page et partagez-la sur vos médias sociaux.</p>
                            </div>
                            <br/>
                            <div className='containerButtonConfigProfile'>
                                <button 
                                    className={`buttonConfigProfile ${!error.status ? "next" : "disable"}`} 
                                    onClick={!error.status ? handleEnd : () => {}}
                                    >
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Créer mon compte"}
                                </button>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className='containerImgLogin'>
                <div className='backgroundImgLogin'></div>
            </div> 
        </div>
    );
}

export default ConfigProfile;
