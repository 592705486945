import  { createSlice } from "@reduxjs/toolkit";

const initialState = {
    passwordIsValid: null,
    emailIsValid: null,
    hobsSpaceIsValid: null,
    errorMessage: "",
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        passwordIsValid(state, action) {
            state.passwordIsValid = action.payload.isValid;
            state.errorMessage = action.payload.message; 
        },
        emailIsValid(state, action) {
            state.emailIsValid = action.payload.isValid;
            state.errorMessage = action.payload.message; 
        },
        hobsSpaceIsValid(state, action) {
            state.hobsSpaceIsValid = action.payload.isValid;
            state.errorMessage = action.payload.message; 
        },
    }
})

export const {passwordIsValid, emailIsValid, hobsSpaceIsValid } = authSlice.actions;