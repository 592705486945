import axios from 'axios';

const apiKey = process.env.REACT_APP_API_KEY_YOUTUBE;

// Fonction pour récupérer l'ID de la chaîne à partir de l'URL personnalisée
const getChannelIdFromCustomUrl = async (user) => {
    const customUrl = user.links.find(link => link.name === "youtube").link;

    try {
        let channelId = null;

        // Si l'URL contient un nom d'utilisateur avec '@'
        if (customUrl.includes('@')) {
            const username = customUrl.split('@')[1]; // Extraire le nom d'utilisateur
            const response = await axios.get(`https://www.googleapis.com/youtube/v3/search?part=snippet&type=channel&q=${username}&key=${apiKey}`);
            const channel = response.data.items[0];
            channelId = channel ? channel.id.channelId : null;
        } 
        // Si l'URL contient un ID direct de la chaîne (avec '/channel/')
        else if (customUrl.includes('/channel/')) {
            channelId = customUrl.split('/channel/')[1]; // Extraire l'ID de chaîne après '/channel/'
        }

        return channelId;
    } catch (error) {
        console.error('Erreur lors de la récupération de l\'ID de la chaîne:', error);
        return null;
    }
};

export const getYoutubeVideoId = async (url, user) => {
    try {
        // Vérifier si l'URL est vide ou invalide
        if (!url) {
            console.log("L'URL est vide. Récupération de la dernière vidéo.");
            const lastVideoId = await getLatestYoutubeVideoId(user);// Appel à la fonction pour obtenir la dernière vidéo
            return lastVideoId;
        }

        const urlObj = new URL(url); // Crée un objet URL à partir du lien fourni
        const videoId = urlObj.searchParams.get("v"); // Récupère la valeur du paramètre "v" (l'ID de la vidéo)

        if (!videoId) {
            throw new Error("Aucun ID de vidéo trouvé dans l'URL.");
        }

        return videoId;
    } catch (error) {
        console.error("Erreur lors de l'extraction de l'ID vidéo:", error.message);
        return null; // Retourne null en cas d'erreur
    }
};

// Fonction pour récupérer les données de la chaîne YouTube
export const getChannelYoutubeData = async (user) => {
    const channelId = await getChannelIdFromCustomUrl(user);
    try {
        const res = await axios.get(
            `https://www.googleapis.com/youtube/v3/channels?part=contentDetails,snippet,id,contentOwnerDetails,statistics&id=${channelId}&key=${apiKey}`
        );
        return res.data.items[0]; // Retourner directement l'objet de la chaîne
    } catch (error) {
        console.error('Erreur lors de la récupération des données de la chaîne:', error);
        return null;
    }
};

// Fonction pour récupérer les dernières vidéos de la chaîne YouTube
export const getLatestYoutubeVideos = async (user, nb = 10) => {
    const channelId = await getChannelIdFromCustomUrl(user);
    try {
        const resultats = await axios.get(
            `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&maxResults=${nb}&videoDuration=medium&order=date&type=video&key=${apiKey}`
        );
        return resultats.data.items;
    } catch (error) {
        console.error('Erreur lors de la récupération des dernières vidéos:', error);
        return null;
    }
};

// Fonction pour récupérer les informations d'une vidéo par son ID
export const getYoutubeVideo = async (id) => {
    try {
        const response = await axios.get(
            `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=${apiKey}&part=snippet,contentDetails,statistics`
        );
        return response.data.items[0];
    } catch (error) {
        console.error('Erreur lors de la requête YouTube :', error);
        return null;
    }
};

// Fonction pour récupérer les vidéos en direct de la chaîne YouTube
export const getYoutubeLive = async (user) => {
    const channelId = await getChannelIdFromCustomUrl(user);
    try {
        const res = await axios.get(
            `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&eventType=live&type=video&key=${apiKey}`
        );
        return res.data.items[0].id.videoId;
    } catch (error) {
        console.error('Erreur lors de la récupération des vidéos en direct:', error);
        return null;
    }
};

// Fonction pour récupérer l'ID de la dernière vidéo d'une chaîne YouTube
export const getLatestYoutubeVideoId = async (user) => {
    const channelId = await getChannelIdFromCustomUrl(user);

    if (!channelId) {
        console.error('Erreur : ID de chaîne introuvable.');
        return null;
    }

    try {
        const response = await axios.get(
            `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&maxResults=1&order=date&type=video&key=${apiKey}`
        );
        
        const video = response.data.items[0];
        
        if (video && video.id && video.id.videoId) {
            return video.id.videoId; // Retourne uniquement l'ID de la vidéo
        } else {
            console.log("Aucune vidéo trouvée pour cette chaîne.");
            return null;
        }
    } catch (error) {
        console.error("Erreur lors de la récupération de la dernière vidéo :", error);
        return null;
    }
};
