import React from 'react';
import PropTypes from 'prop-types';
import styles from "./CallButton.module.css";
import { useHobspace } from "../../../utils/contexts/hobspaceContext";
import { formatPhoneNumber } from '../../../utils';

function CallButton({ phoneNumber }) {
    const { hobspace } = useHobspace();
    const handleCallClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <button onClick={handleCallClick} className={styles.buttonDisplayEmailPhone}>
            <div className={"iconButton"}>
                <i style={{color: hobspace.colorFont}} className={"fa-solid fa-mobile-screen-button"}></i>
            </div>
            <p className='text-lg font-light max-425:text-base' style={{color: hobspace.colorFont}}>{formatPhoneNumber(`+${phoneNumber}`, hobspace.country.code)}</p>
        </button>
    );
}

CallButton.propTypes = {
    phoneNumber: PropTypes.string.isRequired,
    children: PropTypes.node,
};

CallButton.defaultProps = {
    children: null,
};

export default CallButton;
