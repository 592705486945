import React, { useState, useEffect } from 'react'
import '../DashboardPages.css';
import "./HobsPage.css";
import LinkConcert from '../../../components/LinkConcert/LinkConcert';
import Event from '../../../components/Event/Event';
import Link from '../../../components/Link/Link';
import Links from "../../../components/Links/Links";
import OneVideo from '../../../components/OneVideo/OneVideo';
import IndividualVideo from '../../../components/IndividualVideo/IndividualVideo';
import ReseauxSociaux from "../../../components/ReseauxSociaux/ReseauxSociaux";
import Logo from "../../../components/Logo/Logo";
import Menu from "../../../components/Menu/Menu";
import Profil from '../../Profil/Profil';
import CustomModal from '../../../components/Modal/CustomModal/CustomModal';
import MobilePreview from '../../../components/MobilePreview/MobilePreview';
import ButtonEditImage from '../../../components/ButtonEditImage/ButtonEditImage';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../../../services/storage/storageService';
import InputFieldText from '../../../components/InputField/InputFieldText';
import { useUser } from "../../../utils/contexts/userContext";
import InputPhoneNumber from '../../../components/InputField/InputPhoneNumber';
import { links, countries, componentsSpace, formatAncre, generateUniqueId, isValidUrl } from '../../../utils';
import EmailButton from '../../../components/EmailButton/EmailButton';
import CallButton from '../../../components/CallButton/CallButton';
import { setBackgroundColor, setColorFont, setPage, setMenu } from '../../../services/store/reducers/userSlice';
import { Baseline, PaintBucket, SquarePen } from 'lucide-react';
import DragAndDropBlocks from '../../../components/DragAndDropBlocks/DragAndDropBlocks';
import AddContent from '../../AddContent/AddContent';
import VideoCollection from '../../../components/VideoCollection/VideoCollection';
import LinkedinAccount from '../../../components/LinkedinAccount/LinkedinAccount';
import Gallery from '../../../components/Gallery/Gallery';
import DragAndDropLinks from "../../../components/DragAndDropLinks/DragAndDropLinks";
import AddSocial from '../../AddContent/AddSocial';
import Text from "../../../components/Text/Text";
import Media from '../../../components/Media/Media';
import Files from '../../../components/Files/Files';
import File from '../../../components/File/File';
import { listenToAuthState } from '../../../services/auth/authService';

function HobsPage() {

    const images = useSelector((state) => state.user.images);
    const email = useSelector((state) => state.user.email);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [bgdColor, setBgdColor] = useState("");
    const [fontColor, setFontColor] = useState("");
    const [description, setDescription] = useState("");
    const [linkSocialNetwork, setLinkSocialNetwork] = useState([]);
    const [openModalSocial, setOpenModalSocial] = useState(false);
    const [openModalBlocks, setOpenModalBlocks] = useState(false);
    const [openModalUpdateComponent, setOpenModalUpdateComponent] = useState(false);
    const [displayEmail, setDisplayEmail] = useState(false);
    const [displayPhone, setDisplayPhone] = useState(false);
    const { user } = useUser();
    const [linksInit, setLinksInit] = useState(links);
    const [mainComponentsInit, setMainComponentsInit] = useState(componentsSpace);
    const dispatch = useDispatch();
    const [country, setCountry] = useState(user.country);
    const [phoneNumber, setPhoneNumber] = useState('');
    const components = useSelector((state) => state.user.page);

    const handlePhoneNumberChange = (number) => {
        setPhoneNumber(number);
        user.updatePhoneNumber(number);
    };

    const handleChangeCountry = (event) => {
        const selectedCountryCode = event.target.value;
        const selectedCountry = countries.find(c => c.code === selectedCountryCode);
        setCountry(selectedCountry);
        user.updateCountry(selectedCountry);
    };

    const handleChangeFirstname = (newValue) => {
        setFirstname(newValue);
        user.updateFirstname(newValue);

    };

    const handleChangeLastname = (newValue) => {
        setLastname(newValue);
        user.updateLastname(newValue);
    };

    const handleChangeDescription = (newValue) => {
        setDescription(newValue);
        user.updateDescription(newValue);
    };

    const handleChangeFontColor = (newValue) => {
        setFontColor(newValue);
        dispatch(setColorFont(newValue));
        user.updateColorFont(newValue);
    };

    const handleChangeBackgroundColor = (newValue) => {
        setBgdColor(newValue);
        dispatch(setBackgroundColor(newValue));
        user.updateBackgroundColor(newValue);
    };

    const handleSwitchChangeDisplayEmail = () => {
        setDisplayEmail(!displayEmail);
        user.updateContactActive(!user.contactActive.email, displayPhone);
    };

    // Ajouter une notif pour les erreurs
    const handleSwitchChangeDisplayPhone = () => {
        if (user.phoneNumber !== "") {
            setDisplayPhone(!displayPhone);
            user.updateContactActive(displayEmail, !user.contactActive.phone);
        }
        else {

        }

    };

    const handleSaveLinks = (typeLink) => {
        const activeLinkNames = new Set(user.links.map(link => link.name));

        // Collect new active links to add to user.links
        const newActiveLinks = linksInit
        .filter(link => link.name === typeLink && !activeLinkNames.has(link.name))
        .map(link => {
            return { ...link, active: true };
        });

        // Append new active links to user.links without modifying existing order
        user.updateLink([...user.links, ...newActiveLinks]);
        const linksActive = newActiveLinks.map((link, index) => ({
            id: link.name,
            key: index,
            link: link.link,
            active: true,
            icon: link.icon,
            color: link.color
        }));
        setLinkSocialNetwork([...linkSocialNetwork, ...linksActive]);
        setOpenModalSocial(false);
    };

    const removeLinkUser = (name) => {
        const filteredLinks = user.links.filter(link => link.name !== name);
    
        const updatedLinks = filteredLinks.map((link, index) => ({
            id: link.name, 
            key: index,     
            url: link.link, 
            active: true,
            icon: link.icon,
            color: link.color
        }));
    
        setLinkSocialNetwork(updatedLinks);
        user.updateLink(filteredLinks); // Mise à jour des liens de l'utilisateur
    };    

    const removeComponentUser = (id) => {
        const filteredComponents = user.page.filter(component => component.id !== id);
        user.updatePage(filteredComponents);
        dispatch(setPage(user.page));
        const anchors = user.page.map(component => component.title);
        dispatch(setMenu(anchors));
    };

    const handleReorder = (newOrder) => {
        const newOrderLinks = newOrder.map(link => {
            return linksInit.find(l => l.name === link.id);
        }).filter(link => link !== undefined);
        user.reorderLink(newOrderLinks);
        const newOrderLinksActive = newOrderLinks.map((link, index) => ({
            id: link.name,
            key: index,
            link: link.link,
            icon: link.icon,
            color: link.color
        }));
        setLinkSocialNetwork(newOrderLinksActive);
    };

    const updateLinksWithUserActive = (linksInit, userLinks) => {
        return linksInit.map(link => {
            const activeLink = userLinks.find(active => active.name === link.name);
            if (activeLink) {
                return { ...link, active: true, link: activeLink.link };
            }
            return link;
        });
    };

    const handleSaveComponents = (typeComponent) => {
        const newActiveComponents = mainComponentsInit
            .filter(page => page.name === typeComponent)
            .map(page => {
                return { ...page, id: generateUniqueId(), icon: page.name };
            });
            
        user.updatePage([...user.page, ...newActiveComponents]);
        dispatch(setPage(user.page));
        const anchors = user.page.map(component => component.title);
        dispatch(setMenu(anchors));
        handleCancelCheckComponents();
        setOpenModalBlocks(false);
    };

    const handleCancelCheckComponents = () => {
        const resetComponents = mainComponentsInit.map(component => {
            if (component.active) {
                return { ...component, active: false };
            }
            return component;
        });

        // Update the state with the reset links
        setMainComponentsInit(resetComponents);
        setOpenModalBlocks(false);
    };

    const handleReorderComponent = (newOrder) => {
        const newOrderComponents = newOrder.map((orderedComponent) => {
            // Trouver le composant correspondant dans mainComponentsInit par son id
            return user.page.find((component) => component.id === orderedComponent.id);
        }).filter((component) => component !== undefined); // Filtrer les composants non définis

        // Mettre à jour user.page avec le nouvel ordre des composants
        user.reorderPage(newOrderComponents);
        dispatch(setPage(user.page));
        const anchors = user.page.map(component => component.title);
        dispatch(setMenu(anchors));
    };

    const renderComponent = (page) => {
        switch (page.name) {
            case "Link":
                return <Link id={page.id} title={page.title} subtitle={page.subtitle} link={page.link} visibility={page.visibility} />;
            case "Links":
                return <Links id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility}/>;
            case "LinkConcert":
                return <LinkConcert id={page.id} title={page.title} day={page.day} mounth={page.mounth} year={page.year} where={page.where} visibility={page.visibility}/>;
            case "Event":
                return <Event id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility}/>;
            case "Video":
                return <OneVideo id={page.id} idVideo={page.idVideo} description={page.description} visibility={page.visibility}/>;    
            case "IndividualVideo":
                return <IndividualVideo id={page.id} title={page.title} components={page.components} visibility={page.visibility}/>;
            case "VideoCollection":
                return <VideoCollection id={page.id} ancre={formatAncre(page.title)} title={page.title} nbVideo={page.nbVideo} visibility={page.visibility}/>;
            case "LinkedinAccount":
                return <LinkedinAccount id={page.id} ancre={formatAncre(page.title)} title={page.title} description={page.description} visibility={page.visibility}/>;
            case "Text":
                return <Text id={page.id} ancre={formatAncre(page.title)} title={page.title} description={page.description} visibility={page.visibility}/>;
            case "Gallery":
                return <Gallery id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility}/>;
            case "Media":
                return <Media id={page.id} ancre={formatAncre(page.title)} description={page.description} link={page.link} type={page.type} visibility={page.visibility}/>;
            case "File":
                return <File id={page.id} title={page.title} subtitle={page.subtitle} visibility={page.visibility} />;
            case "Files":
                return <Files id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility}/>;      
            default:
                return null;
        }
    };

    useEffect(() => {
        console.log(images);
        listenToAuthState(dispatch, images);
        if (user) {
            const userCountry = countries.find(c => c.code === user.country.code);
            setCountry(userCountry);
            setLastname(user.lastname);
            setFirstname(user.firstname);
            setDescription(user.description);
            setBgdColor(user.backgroundColor);
            setFontColor(user.colorFont);
            dispatch(setBackgroundColor(user.backgroundColor));
            dispatch(setColorFont(user.colorFont));
            setDisplayEmail(user.contactActive.email);
            setDisplayPhone(user.contactActive.phone);
            setPhoneNumber(user.phoneNumber);
            const linksActive = user.links.filter(link => link.active).map((link, index) => ({
                id: link.name,
                key: index,
                active: true,
                link: link.link,
                icon: link.icon,
                color: link.color
            }));
            const pageActive = user.page.map((page, index) => ({
                id: page.id,
                key: index,
                name: page.name,
                icon: page.icon,
                color: page.color,
                visibility: page.visibility,
                ...( ["Links", "IndividualVideo", "Event", "Gallery", "Files"].includes(page.name) && { components: page.components } ),
                title: page.title,
                ...( ["Link", "LinkConcert", "Media"].includes(page.name) && { link: page.link } ),
                ...( ["LinkedinAccount", "Text", "Media"].includes(page.name) && { description: page.description } ),
                ...( page.name === "Video" && { idVideo: page.idVideo } ),
                ...( page.name === "VideoCollection" && { nbVideo: page.nbVideo } ),
                ...( ["Media"].includes(page.name) && { type: page.type } ),
            }))
            dispatch(setPage(pageActive));
            const linksInitActive = updateLinksWithUserActive(linksInit, user.links);
            setLinkSocialNetwork(linksActive);
            setLinksInit(linksInitActive);
            const anchors = user.page.map(component => component.title);
            dispatch(setMenu(anchors));
            console.log(images);
        }
    }, [user]);    

    return (
        <>
            <div className='DashboardPages'>
                <Profil />
                <div className='SectionHobsSpace' >
                    <div className='containerYourPage mt-1'>
                        <div className='containerConfigYourPage'>
                            <div className='containerApparence'>
                                <div className='containerSettingsApparence'>
                                    <div className='headerSettingsApparence'>
                                        <p className='titleSettingsApparence text-xl'>Profil</p>
                                        <div className='buttonGroup p-4'>
                                            <button
                                                className='p-2 border-4 border-solid border-gray-600 rounded-full'
                                                style={{ backgroundColor: bgdColor, color: fontColor }}
                                                onClick={() => document.getElementById('colorInput').click()}
                                            >
                                                <PaintBucket color={fontColor} size={20} />
                                            </button>
                                            <input
                                                id="colorInput"
                                                type="color"
                                                className='sr-only'
                                                onChange={() => handleChangeBackgroundColor(document.getElementById('colorInput').value)}
                                            />
                                            <button
                                                className='p-2 border-4 border-solid border-gray-600 rounded-full'
                                                style={{ backgroundColor: fontColor, color: bgdColor }}
                                                onClick={() => document.getElementById('colorInputFont').click()}
                                            >
                                                <Baseline color={bgdColor} size={20} />
                                            </button>
                                            <input
                                                id="colorInputFont"
                                                type="color"
                                                className='sr-only'
                                                onChange={() => handleChangeFontColor(document.getElementById('colorInputFont').value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-between '>
                                        <div className='relative mr-10 w-40'>
                                            {images !== undefined ?
                                                <img className='w-28 h-28 rounded-full' src={images} alt="" style={{objectFit: "cover"}} />
                                                :
                                                <div className='w-28 h-28 rounded-full flex items-center overflow-hidden justify-center' style={{backgroundColor: bgdColor}}>
                                                    <i style={{ color: fontColor, fontSize: "100px", position: "relative", top: "15px" }} className={"fa-regular fa-user"}></i>
                                                </div>
                                            }
                                            <div className='absolute bottom-0 left-20'>
                                                <ButtonEditImage
                                                    title={"Charger une photo de profile"}
                                                    folder={"profile"}
                                                    nameFile={"profile-image"}
                                                    ctaModalClassName={"flex justify-center items-center w-10 h-10 rounded-full border-4 border-solid border-black"}
                                                    ctaModalStyle={{ backgroundColor: "#ff8500", color: "#FFF" }}
                                                    ctaModalTitle={<SquarePen color="#fff" size={20} />}
                                                />
                                            </div>
                                            <div className='absolute top-0 left-20'>
                                                <button
                                                    className="w-10 h-10 rounded-full border-4 border-solid border-black"
                                                    style={{ backgroundColor: '#ff8500', color: '#fff' }}
                                                    onClick={() => document.getElementById('countrySelect').classList.toggle('sr-only')}
                                                >
                                                    <div className="flex justify-center">
                                                        <img
                                                            className="w-6 h-6"
                                                            alt={country.name}
                                                            src={"http://purecatamphetamine.github.io/country-flag-icons/3x2/" + country.code + ".svg"}
                                                        />
                                                    </div>
                                                </button>
                                                <select
                                                    id="countrySelect"
                                                    value={country.code}
                                                    onChange={handleChangeCountry}
                                                    className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
                                                >
                                                    <option value="" disabled>Select a country</option>
                                                    {countries.map((countryOption) => (
                                                        <option key={countryOption.code} value={countryOption.code}>
                                                            {countryOption.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='containerInputFirstnameLastname'>
                                            <InputFieldText value={lastname} onChange={(e) => handleChangeLastname(e.target.value)} title={"Nom"} type={true} maxLength={30} />
                                            <InputFieldText value={firstname} onChange={(e) => handleChangeFirstname(e.target.value)} title={"Prénom"} type={true} maxLength={30} />
                                        </div>
                                    </div>
                                    <InputFieldText value={description} onChange={(e) => handleChangeDescription(e.target.value)} title={"votre Description"} type={false} placeholder={"Entrer une description de 140 caractères maximum"} maxLength={140} />
                                    <div className='containerDisplayEmailPhone'>

                                        <div className='flex justify-between items-center mb-3'>
                                            <p className='font-semibold'>Coordonnées</p>
                                            <p className='font-semibold'>Afficher ?</p></div>
                                        <div className='containerDisplayEmail mb-2'>
                                            <p className='emailDisplayEmail font-semibold'>{email}</p>
                                            <label className="switch">
                                                <input type="checkbox" checked={displayEmail} onChange={handleSwitchChangeDisplayEmail} />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                        <div className='containerDisplayEmail font-semibold'>
                                            <InputPhoneNumber
                                                className="text-xl"
                                                title=""
                                                value={phoneNumber !== "" && phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                                required={false}
                                                country={country.code}
                                            />
                                            <label className="switch">
                                                <input type="checkbox" checked={displayPhone} onChange={handleSwitchChangeDisplayPhone} />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='containerBlockList'>
                                    <div className='flex justify-center flex-col items-center mt-3 w-full'>
                                        <p className='titleSettingsApparence text-xl w-full text-left'>Vos Réseaux Sociaux</p>
                                        <button className='flex justify-center items-center w-96 buttonAddNewBlock' onClick={() => setOpenModalSocial(true)}>
                                            <div className='iconButton'>
                                                <i style={{ color: "#fff" }} className={"fa-solid fa-plus"}></i>
                                            </div>
                                            Ajouter un lien social
                                        </button>
                                    </div>
                                    <DragAndDropLinks droppableId={"SocialList"} initialLinks={linkSocialNetwork} deleteLink={removeLinkUser} onReorder={handleReorder} />
                                </div>
                                <div className='containerBlockList'>
                                    <div className='flex justify-center flex-col items-center mt-3 w-full'>
                                        <p className='titleSettingsApparence text-xl w-full text-left'>Vos Composants</p>
                                        <button className='flex justify-center items-center w-96 buttonAddNewBlock' onClick={() => setOpenModalBlocks(true)}>
                                            <div className='iconButton'>
                                                <i style={{ color: "#fff" }} className={"fa-solid fa-plus"}></i>
                                            </div>
                                            Ajouter un nouveau composant
                                        </button>
                                    </div>

                                    <DragAndDropBlocks droppableId={"BlockList"} initialContainers={components} deleteBlock={removeComponentUser} onReorder={handleReorderComponent} />
                                </div>
                                <div className='containerSettingsApparence'>
                                    <div className='containerSubmitChange'>
                                        <button onClick={() => updateUser(user, email, dispatch)} className='buttonStartPricingPack'>Enregistrer les modifications</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-2/6 mt-5 ContainerPreviewHobsSpace'>
                        <div className='containerHeaderPreviewHobsSpace'>
                            <p className='titleHeaderPreviewHobsSpace'>Aperçu</p>
                        </div>
                        <div className='lineHobsSpace'></div>
                        <MobilePreview bgd={bgdColor}>
                            {user && <>
                                <Logo user={user} componentId={"Profile"} image={images} />
                                {displayEmail && <EmailButton email={email} subject="Mon Hob's Space" body="Bonjour, venez jeter un oeil à mon Hob's Space !" />}
                                {displayPhone && <CallButton phoneNumber={user.phoneNumber} />}
                                <ReseauxSociaux socialNetworks={user.links} />
                                <Menu />
                                <div className='lineSeparation'></div>
                                {components && components.map((component, index) => (
                                    <React.Fragment key={index}>
                                        {renderComponent(component)}
                                        {index < components.length - 1 && <div className='lineSeparation'></div>}
                                    </React.Fragment>
                                ))}
                                {/* <QRCodeButton /> */}
                            </>}
                        </MobilePreview>
                    </div>
                </div>
            </div>
            <CustomModal isOpen={openModalSocial} onClose={() => setOpenModalSocial(false)} width="calc(100% - 172.69px)" height="100vh" margin="0px 0 0 172.69px" bgOverlay="transparent">
                <AddSocial setOpenModal={() => setOpenModalSocial(false)} addComponent={handleSaveLinks} />
            </CustomModal>
            <CustomModal isOpen={openModalBlocks} onClose={() => setOpenModalBlocks(false)} width="calc(100% - 172.69px)" height="100vh" margin="0px 0 0 172.69px" bgOverlay="transparent" >
                <AddContent setOpenModal={() => setOpenModalBlocks(false)} addComponent={handleSaveComponents} />    
            </CustomModal>
            
        </>    
    );
}

export default HobsPage;