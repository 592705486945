import React from 'react'
import styles from './LinkConcert.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function LinkConcert(props) {
    const {hobspace} = useHobspace();

 
    return (
        
        <a className={styles.LinkConcert} style={{ border: `${hobspace.backgroundColor} 1px solid`, color: hobspace.colorFont }} href={props.type ? props.link : null} rel="noreferrer" target='_blank'>
            <div className={`containerInfoConcert`}>
                <p className={`${styles.event} text-xl max-md:text-lg max-425:text-base max-350:text-sm line-clamp-1 max-md:line-clamp-2`}>{props.title}</p>
                <p className={`${styles.where} text-lg max-md:text-base max-425:text-sm max-350:text-xs line-clamp-1 max-md:line-clamp-2`}>{props.where}</p>
                {props.type ? (
                    <a className={styles.ticketOk} style={{ color: hobspace.colorFont }} href={""} rel="noreferrer" target='_blank'>Tickets</a>
                ) : (
                    <p className={styles.ticketSoldout}>Sold out</p>
                )}
            </div>
            <div className={styles.separationTicket}>
                <div className={styles.bulleTop} style={{ backgroundColor: hobspace.backgroundColor }}></div>
                <div className={styles.bulles}>
                    {[1, 2, 3, 4, 5].map((_, index) => (
                        <div key={index} className={styles.bul} style={{ backgroundColor: hobspace.backgroundColor }}></div>
                    ))}
                </div>
                <div className={styles.bulleBottom} style={{ backgroundColor: hobspace.backgroundColor }}></div>
            </div>
            <div className={styles.dateConcert}>
                <div className={styles.containerDateConcert}>
                    <p className={styles.mounth}>{props.mounth}</p>
                    <p className={styles.day}>{props.day}</p>
                    <p className={styles.year}>{props.year}</p>
                </div>
            </div>
        </a>
    );
}

export default LinkConcert;