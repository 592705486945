import React, {useEffect, useState} from "react";

import "./Question.css";

export default function Question(props) {

    useEffect(() => {
    }, [])

    const [activeQuestion, setActiveQuestion] = useState(false);

    return (
        <div className={"container-question"}>
            <div className='question' onClick={() => setActiveQuestion(!activeQuestion)}>
                <p className='title-question'>{props.title}</p>
                <div className={`iconButtonQuestion ${activeQuestion ? 'active' : ''}`}>
                    <i style={{ color: '#fff' }} className="fa-solid fa-chevron-up"></i>
                </div>
            </div>
            {activeQuestion &&
                <p className={"answer-question"}>
                    {props.answer} 
                </p>
            }
            
            
        </div>
    );
}