import React, {useState, useEffect} from 'react';
import './NavigationHome.css';
import Logo from "../../../assets/logo.png";
import { Link } from "react-router-dom";
import { useUser } from "../../../utils/contexts/userContext";
import { logout } from '../../../services/auth/authService';
import { login } from '../../../services/store/reducers/userSlice';
import { useDispatch, useSelector } from "react-redux";
import { getImages } from '../../../services/storage/storageService';
import { setImages } from '../../../services/store/reducers/userSlice';

function NavigationHome(props) {
    const [showUserProfileMenu, setShowUserProfileMenu] = useState(false);
    const loginState = useSelector((state) => state.user.login);
    //const [images, setImages] = useState([]);
    const images = useSelector((state) => state.user.images);
    const email = useSelector((state) => state.user.email);
    const imgChange = useSelector((state) => state.user.uploadImage);
    const { user, loading } = useUser();
    const dispatch = useDispatch();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogout = async () => {
        await logout(dispatch);
    };

    const toggleUserProfileMenu = () => {
        setShowUserProfileMenu(!showUserProfileMenu);
    };

    return (
        <>
        <div className='NavigationHome' style={{borderColor: `rgba(${props.color}, 0.3)`}}>
            <Link to={"/"} className='containerLogoNavigationHome' ><img className='logo' src={Logo} alt='Hobs space logo' /></Link>
            <div className='containerNavigationButtonMobile'>
                <button className='containerHamburgermenu' onClick={toggleMenu}>
                    <div className={`linehamburger ${menuOpen ? 'open' : ''}`}></div>
                    <div className={`linehamburger ${menuOpen ? 'open' : ''}`}></div>
                    <div className={`linehamburger ${menuOpen ? 'open' : ''}`}></div>
                </button>
                <div className='containerHamburgerMenuMobile' style={menuOpen ? {right: "0"} : {right: "-210px"}}>
                    <Link to="#adopterHobspace" className='linkNavigation' >Adapoter Hob's Space</Link>
                    {/* <Link to={"/pricing"} className='linkNavigation' >Tarif</Link> */}
                    <Link to={"/support"} className='linkNavigation' >Support</Link>
                    <Link to={"/contact"} className='linkNavigation' >Contact</Link>
                    {loginState ? (
                        <>
                            {!loading && user ? (
                                <button className='linkNavigationProfil signup' onClick={toggleUserProfileMenu}>
                                    {images !== undefined ? 
                                        <img className='imgProfilNavigation' src={images} alt="profil" />
                                    :
                                        <div className='imgProfilNavigation'>
                                            <i className={"fa-regular fa-user"}></i>
                                        </div>
                                    }
                                    <p className='nameProfilNavigation'>{user.nameSpace}</p>
                                </button>
                            ) : (
                                null
                            )}
                        </>
                    ):(
                        <>
                            <Link to={"/login"} className='linkNavigation' >Se Connecter</Link>
                            <Link to={"/signup"} className='linkNavigation signup' >S'inscrire</Link>
                        </>
                    )}      
                </div>
            </div>
            <div className='containerNavigationButton'>
                <Link to="#adopterHobspace" className='linkNavigation' >Adapoter Hob's Space</Link>
                {/* <Link to={"/pricing"} className='linkNavigation' >Tarif</Link> */}
                <Link to={"/support"} className='linkNavigation' >Support</Link>
                <Link to={"/contact"} className='linkNavigation' >Contact</Link>
                {loginState ? (
                    <>
                        {!loading && user ? (
                            <button className='linkNavigationProfil signup' onClick={toggleUserProfileMenu}>
                                {images !== undefined ? 
                                    <img className='imgProfilNavigation' src={images} alt="profil" />
                                :
                                    <div className='imgProfilNavigation'>
                                        <i className={"fa-regular fa-user"}></i>
                                    </div>
                                }
                                <p className='nameProfilNavigation'>{user.nameSpace}</p>
                            </button>
                        ) : (
                            null
                        )}
                    </>
                ):(
                    <>
                        <Link to={"/login"} className='linkNavigation' >Se Connecter</Link>
                        <Link to={"/signup"} className='linkNavigation signup' >S'inscrire</Link>
                    </>
                )}      
                
            </div>
         </div>
        {!loading && user &&
            <div className={`userProfileMenu ${showUserProfileMenu ? "active" : ""}`}>
                <button className="closeButton" onClick={toggleUserProfileMenu}>X</button>
                <div className="userInfo">
                    {images !== undefined ? 
                        <img src={images} alt="Profile" className="profilePicture" />
                    :
                        <div className='profilePicture'>
                            <i className={"fa-regular fa-user"}></i>
                        </div>
                    }
                    <p className="userName">{user.nameSpace}</p>
                </div>
                <Link to="/" className="logoutButton" onClick={handleLogout}>Se déconnecter</Link>
                <Link to="/dashboard" className="manageProfileButton">Gérer le profil</Link>
                {/* Ajoutez d'autres options de profil ici */}
            </div>
        }
        
        </>
    );
}

export default NavigationHome;
