import React from 'react'
import './LinkedinAccount.css';
import Title from '../Title/Title';
import LinkedinProfil from '../LinkedinProfil/LinkedinProfil';
import { useUser } from '../../utils/contexts/userContext';

function LinkedinAccount(props) {
  const {user} = useUser();

    return (
      <div className='LinkedinAccount'>
        {props.title &&
          <Title id={props.ancre} color={user.colorFont} title={props.title} marginBottom={true} />
        }
        <LinkedinProfil description={props.description} />
      </div>
    );
}

export default LinkedinAccount;