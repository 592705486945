export default class Hobspace {
    constructor(data) {
        this.nameSpace = data.nameSpace;
        this.firstname = data.firstname;
        this.description = data.description;
        this.lastname = data.lastname;
        this.phoneNumber = data.phoneNumber;
        this.country = data.country;
        this.colorFont = data.colorFont;
        this.backgroundColor = data.backgroundColor;
        this.links = data.links;
        this.page = data.page;
        this.pageInit = data.pageInit;
        this.subscription = data.subscription;
        this.contactActive = data.contactActive;
    };
  
    findPageByName(name) {
        return this.pageInit.find(page => page.name === name);
    }

    static fromFirestore(data) {
        return new Hobspace(data);
    }

}
