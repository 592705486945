import React from 'react'
import styles from './ReseauSocial.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function ReseauSocial(props) {

    const {hobspace} = useHobspace();

    return (
        <a className={styles.ReseauSocial} href={props.link} target='_blank' rel="noreferrer">
            <div className='w-full h-full'>
                <i style={{color: hobspace.colorFont}} className={props.img}></i>
            </div>
        </a>
    );
}

export default ReseauSocial;