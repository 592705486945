import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './DragAndDropBlocks.css'; 
import YtbColor from "../../assets/youtube-color.png";
import Ytb from "../../assets/youtube.png";
import Type from "../../assets/type.png";
import TicketLogo from "../../assets/ticket.png";
import File from "../../assets/file.png";
import Linkedin from "../../assets/linkedin.png"
import LinkDuonote from "../../assets/link.png";
import ImgMedia from "../../assets/image.png";
import IndividualVideoEdit from '../IndividualVideo/IndividualVideoEdit';
import FileEditComponent from '../File/FileEditComponent';
import FilesEdit from '../Files/FilesEdit';
import EventEdit from '../Event/EventEdit';
import LinksEdit from '../Links/LinksEdit';
import LinkedinAccountEdit from '../LinkedinAccount/LinkedinAccountEdit';
import TextEdit from '../Text/TextEdit';
import GalleryEdit from '../Gallery/GalleryEdit';
import MediaEditComponent from '../Media/MediaEditComponent';
import LinkEditComponent from '../Link/LinkEditComponent';
import LinkConcertEditComponent from '../LinkConcert/LinkConcertEditComponent';
import OneVideoEditComponent from '../OneVideo/OneVideoEditComponent';
import VideoCollectionEditComponent from '../VideoCollection/VideoCollectionEditComponent';
import { GripHorizontal } from "lucide-react"

function DragAndDropBlocks({ initialContainers, deleteBlock, onReorder, droppableId, }) {
    const [containers, setContainers] = useState(initialContainers);

    useEffect(() => {
        setContainers(initialContainers);
    }, [initialContainers]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(containers);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setContainers(items);
        if (onReorder) {
            onReorder(items);
        }
    };

    const renderComponentName = (name) => {
        switch (name) {
            case "Link":
                return "Url Basic";
            case "Links":
                return "Liste d'url Basic";
            case "Event":
                return "List liens Evènementiel";
            case "LinkConcert":
                return "Lien de concert";
            case "Video":
                return "Vidéo youtube ";
            case "IndividualVideo":
                return "Vidéo individuelle ";
            case "VideoCollection":
                return "Collection de vidéos";
            case "LinkedinAccount":
                return "Compte Linkedin";
            case "Text":
                return "Zone de texte";
            case "Gallery":
                return "Gallerie d'images/vidéos";
            case "Media":
                return "Lien images/vidéos";
            case "File":
                return "Fichier PDF";
            case "Files":
                return "Liste de fichier PDF";
            default:
                return name;
        }
    };

    const renderComponentIcon = (name) => {
        switch (name) {
            case "Link":
                return LinkDuonote;
            case "Links":
                return LinkDuonote;
            case "Event":
                return TicketLogo;
            case "LinkConcert":
                return TicketLogo;
            case "IndividualVideo":
                return Ytb;
            case "Video":
                return YtbColor;
            case "VideoCollection":
                return Ytb;
            case "LinkedinAccount":
                return Linkedin;
            case "Text":
                return Type;
            case "Gallery":
                return ImgMedia;
            case "Media":
                return ImgMedia;
            case "File":
                return File;
            case "Files":
                return File;
            default:
                return name;
        }
    }

    const renderComponentContent = (name, container) => {
        
        switch (name) {
            case "Link":
                return <LinkEditComponent linkContainer={container} />;
            case "LinkConcert":
                return <LinkConcertEditComponent container={container} />;
            case "Video":
                return <OneVideoEditComponent container={container} renderComponentIcon={renderComponentIcon} />;
            case "Links":
                return <LinksEdit linkContainer={container} deleteBlock={deleteBlock} renderComponentContent={renderComponentContent} />;                
            case "Event": 
                return <EventEdit container={container} deleteBlock={deleteBlock} renderComponentContent={renderComponentContent}/>;
            case "IndividualVideo": 
                return <IndividualVideoEdit container={container} deleteBlock={deleteBlock} renderComponentContent={renderComponentContent}/>;
            case "VideoCollection": 
                return <VideoCollectionEditComponent container={container} renderComponentIcon={renderComponentIcon} deleteBlock={deleteBlock} />;
            case "LinkedinAccount": 
                return <LinkedinAccountEdit container={container} deleteBlock={deleteBlock} />;
            case "Text": 
                return <TextEdit container={container} deleteBlock={deleteBlock} />;
            case "Gallery": 
                return <GalleryEdit container={container} deleteBlock={deleteBlock} renderComponentContent={renderComponentContent} />;
            case "Media":
                return <MediaEditComponent container={container} />;
            case "File":
                return <FileEditComponent container={container} />;
            case "Files":
                return <FilesEdit container={container} deleteBlock={deleteBlock} renderComponentContent={renderComponentContent} />;                
                
            default:
                return <span>{name}</span>; // Retourne un composant JSX pour le 'default'
        }
    };
    

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId={droppableId}>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="container-list h-auto"
                    >
                        {containers && containers.map((container, index) => (
                            <Draggable key={container.id} draggableId={container.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={`h-auto bg-neutral-100 draggable-block ${snapshot.isDragging ? 'dragging' : ''}`}
                              >
                                <div className='flex-row w-full'>
                                  <div className='flex items-center w-auto justify-between'>
                                    <div
                                      className='iconDragAndDrop flex p-2 text-white items-center flex-start bg-neutral-100 rounded-lg'
                                      style={{ backgroundColor: container.color }}
                                    >
                                      <img
                                        className='h-8 w-8 object-center object-contain invert'
                                        src={renderComponentIcon(container.icon)}
                                        alt="icon"
                                      />
                                      <p className='ml-1 text-white'>{renderComponentName(container.name)}</p>
                                    </div>
                                    
                          
                                    <div className='flex cursor-grab' {...provided.dragHandleProps}>
                                        <GripHorizontal color="#475569" />
                                    </div>
                                  </div>
                                    {renderComponentContent(container.name, container)}
                                  </div>  
                              </div>
                            )}
                          </Draggable>
                          
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default DragAndDropBlocks;
