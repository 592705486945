import React from 'react'
import './ButtonMenu.css';
import '@fortawesome/fontawesome-free/css/all.css';


function ButtonMenu(props) {
 
    return (
        <button className={`ButtonMenu ${props.active ? 'active' : ""}`} style={{color: props.active ? "#ff8500" : `rgba(${props.color}, 1)`}} onClick={props.onClick}>
            <div className='iconButton'>
                <i style={{color: props.active ? "#ff8500" : "#fff"}} className={props.icon}></i>
            </div>
            {props.title}
        </button>
    );
}

export default ButtonMenu;