import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import CustomModal from '../Modal/CustomModal/CustomModal';
import { uploadImage } from '../../services/storage/storageService';
import { useUser } from "../../utils/contexts/userContext";
import DragAndDropContainer from '../DragAndDropContainer/DragAndDropContainer';
import InputFieldText from '../InputField/InputFieldText';
import { componentsSpace, generateUniqueId } from '../../utils';
import { useDispatch } from "react-redux";
import { Trash2, PencilLine } from 'lucide-react';
import { setPage, setMenu } from '../../services/store/reducers/userSlice';

function GalleryEdit({ renderComponentContent, container, deleteBlock }) {
    const { user } = useUser();   
    const [visibility, setVisibility] = useState(true);
    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState(null);
    const dispatch = useDispatch();

    const handleChangeTitle = (newValue) => {
        setTitle(newValue);
    };

    const updateTitleComponent = (id) => { // Renommé fonction pour plus de clarté
        if (title !== null) {
            user.updateObjectInPageById(id, { title: title });
            dispatch(setPage(user.page));
            const anchors = user.page.map(component => component.title);
            dispatch(setMenu(anchors));
        }
        setEdit(false);
    };

    const removeComponents = (id) => {
        user.removeObjectById(id);
        dispatch(setPage(user.page));
    };

    const reorderListById = (list, newOrder) => {
        const orderMap = new Map(newOrder.map((item, index) => [item.id, index]));
        return list.slice().sort((a, b) => orderMap.get(a.id) - orderMap.get(b.id));
    };

    const handleReorderComponents = (id, newOrder) => {
        const pageComponent = user.page.find((component) => component.id === id);
        if (!pageComponent || !pageComponent.components) {
            console.error("L'élément avec cet ID n'existe pas ou n'a pas de composants.");
            return;
        }
        const newOrderComponents = reorderListById(pageComponent.components, newOrder);
        user.reorderObjectToComponents(id, newOrderComponents);
        dispatch(setPage(user.page));
    };

    const updateComponentsUser = (componentToAdd, id) => {
        const newActiveComponents = componentsSpace.find(page => page.name === componentToAdd);
        if (newActiveComponents) {
            const updatedComponent = { ...newActiveComponents, id: generateUniqueId() };
            user.addObjectToComponents(id, updatedComponent);
            dispatch(setPage(user.page));
        }
    };

    useEffect(() => {
        if(container) {
            setTitle(container.title);
        }
    }, [container]);

    return (
        <>
        <div className='flex h-auto mt-2 items-center'>
            {container &&
            <div className='flex flex-col w-full'>
                <div className='flex flex-row w-full justify-between gap-6'>
                    {edit ? (
                        <InputFieldText 
                            value={title} 
                            maxLength={30}
                            onChange={(e) => handleChangeTitle(e.target.value)} 
                            placeholder={title} 
                            title={""} 
                            type={true}
                            onBlur={() => updateTitleComponent(container.id)} // Appel de la nouvelle fonction renommée
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    updateTitleComponent(container.id);
                                }
                            }}
                        />
                    ) : (
                        <p className="mr-2 text-lg capitalize">{title}</p>
                    )}

                    <div className='flex flex-row gap-2'>
                        <button className='buttonDragAndDrop' onClick={() => setVisibility(!visibility)}>
                            {visibility ? (
                                <i className="fa-solid fa-eye" style={{ color: "#000" }}></i>
                            ) : (
                                <i className="fa-solid fa-eye-slash" style={{ color: "#000" }}></i>
                            )}
                        </button>
                        <button className='buttonDragAndDrop' onClick={() => setEdit(!edit)}>
                            <PencilLine />
                        </button>
                        <button className='buttonDragAndDrop' onClick={() => deleteBlock(container.id)}>
                            <Trash2 />
                        </button>
                    </div>
                </div>

                <div className='textDragAndDrop flex items-center justify-between mt-2'>
                    <div className="flex items-start flex-col w-full">
                        <button
                            onClick={() => updateComponentsUser("Media", container.id)}
                            className="flex p-2 text-xs text-white items-center flex-start bg-neutral-100 rounded-full"
                            style={{ backgroundColor: container.color }}
                        >
                            Ajouter du contenu
                        </button>

                        <div className="flex flex-col mt-3 w-full max-h-[250px] overflow-y-auto p-4 border border-gray-300 rounded-lg">
                            {container.components && container.components.length > 0 ? (
                                <DragAndDropContainer
                                    droppableId={container.id}
                                    initialContainers={container.components.map((component, index) => ({
                                        ...component,
                                        renderContent: () => (
                                            <React.Fragment key={index}>
                                                {renderComponentContent("Media", component)}
                                            </React.Fragment>
                                        )
                                    }))}
                                    deleteBlock={(id) => removeComponents(id)}
                                    onReorder={(reorderedComponents) =>
                                        handleReorderComponents(container.id, reorderedComponents)
                                    }
                                    editComponent={true}
                                />
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
            {/* <button
                className={`${props.ctaModalClassName || 'px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600'}`}
                onClick={handleButtonClick}
                style={{ color: `rgba(${props.color}, 1)`, borderColor: `rgba(${props.color}, 1)` }}
            >
                {props.ctaModalTitle || "Sélectionner un média"}
            </button>
            <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
                <div className="p-6 bg-white rounded-lg shadow-lg">
                    <p className="text-lg font-semibold mb-4">{props.title}</p>
                    <div
                        className="flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-4 cursor-pointer"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={handleModalClick}
                    >
                        {previewSrc ? (
                            <div className="w-full max-h-64 overflow-hidden rounded-lg mb-4">
                                {file.type.startsWith('image/') ? (
                                    <img className="w-full h-auto" src={previewSrc} alt="preview" />
                                ) : (
                                    <video className="w-full" src={previewSrc} controls />
                                )}
                            </div>
                        ) : (
                            <div className="text-gray-500 text-4xl mb-4">
                                <i className="fa-solid fa-cloud-arrow-up"></i>
                            </div>
                        )}
                        <p className={`text-center ${error ? 'text-red-500' : 'text-gray-600'}`}>
                            {error ? "Fichier invalide ou trop volumineux." : "Déposez un fichier ou cliquez ici pour sélectionner un fichier."}
                        </p>
                    </div>
                    <div className="flex justify-end mt-4 space-x-2">
                        <button className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400" onClick={handleCloseModal}>Annuler</button>
                        <button className={`px-4 py-2 ${file ? 'bg-blue-500 hover:bg-blue-600 text-white' : 'bg-gray-300 text-gray-800 cursor-not-allowed'}`} onClick={file ? uploadFile : () => setError(true)}>Enregistrer</button>
                    </div>
                </div>
            </CustomModal> */}
        </>
    );
}

export default GalleryEdit;
