import React, {useState, useEffect} from 'react'
import styles from './Home.module.css';
//----- Components Demo -------------------------------------------
import LinkConcert from '../components/LinkConcert/LinkConcert';
import Event from '../components/Event/Event';
import Link from '../components/Link/Link';
import Links from "../components/Links/Links";
import OneVideo from '../components/OneVideo/OneVideo';
import IndividualVideo from '../components/IndividualVideo/IndividualVideo';
import ReseauxSociaux from "../components/ReseauxSociaux/ReseauxSociaux";
import Logo from "../components/Logo/Logo";
import Menu from "../components/Menu/Menu";
import EmailButton from '../components/EmailButton/EmailButton';
import CallButton from '../components/CallButton/CallButton';
import VideoCollection from '../components/VideoCollection/VideoCollection';
import LinkedinAccount from '../components/LinkedinAccount/LinkedinAccount';
import Gallery from '../components/Gallery/Gallery';
import Text from "../components/Text/Text";
import Media from '../components/Media/Media';
import Files from '../components/Files/Files';
import File from '../components/File/File';
//----------------------------------------------------------------
import { useSelector, useDispatch } from 'react-redux';
import { useHobspace } from '../../utils/contexts/hobspaceContext';
import { capitalizeFirstLetter, changeBodyBackgroundColor, formatAncre } from '../../utils';
import Loader from '../components/Loader/Loader';
import { getImages } from '../../services/storage/storageService';
import { getHobspaceInfo, getEmailUser } from '../../services/storage/storageHobspaceService';
import TitleTabNavigator from '../components/TitleTabNavigator/TitleTabNavigator';
import { setImages } from '../../services/store/reducers/userSlice';
    
function Home({ hobspaceId }) { // Reçois l'ID en tant que prop
    const [email, setEmail] = useState(undefined);
    const [image, setImage] = useState(null);
    const [bgdColor, setBgdColor] = useState("");
    const [fontColor, setFontColor] = useState("");
    const [displayEmail, setDisplayEmail] = useState(false);
    const [displayPhone, setDisplayPhone] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [component, setComponent] = useState([]);
    const [menu, setMenu] = useState([]);
    const { hobspace, loading, setHobspace } = useHobspace();
    const dispatch = useDispatch();

    const renderComponent = (page) => {
        switch (page.name) {
            case "Link":
                return <Link id={page.id} title={page.title} subtitle={page.subtitle} link={page.link} visibility={page.visibility} />;
            case "Links":
                return <Links id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility} email={email} />;
            case "LinkConcert":
                return <LinkConcert id={page.id} title={page.title} day={page.day} mounth={page.mounth} year={page.year} where={page.where} visibility={page.visibility}/>;
            case "Event":
                return <Event id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility}/>;
            case "Video":
                return <OneVideo id={page.id} idVideo={page.idVideo} description={page.description} visibility={page.visibility}/>;    
            case "IndividualVideo":
                return <IndividualVideo id={page.id} title={page.title} components={page.components} visibility={page.visibility}/>;
            case "VideoCollection":
                return <VideoCollection id={page.id} ancre={formatAncre(page.title)} title={page.title} nbVideo={page.nbVideo} visibility={page.visibility}/>;
            case "LinkedinAccount":
                return <LinkedinAccount id={page.id} ancre={formatAncre(page.title)} title={page.title} description={page.description} visibility={page.visibility} image={image}/>;
            case "Text":
                return <Text id={page.id} ancre={formatAncre(page.title)} title={page.title} description={page.description} visibility={page.visibility}/>;
            case "Gallery":
                return <Gallery id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility} email={email} />;
            case "Media":
                return <Media id={page.id} ancre={formatAncre(page.title)} description={page.description} link={page.link} type={page.type} visibility={page.visibility}/>;
            case "File":
                return <File id={page.id} title={page.title} subtitle={page.subtitle} visibility={page.visibility} />;
            case "Files":
                return <Files id={page.id} ancre={formatAncre(page.title)} title={page.title} components={page.components} visibility={page.visibility} email={email} />;      
            default:
                return null;
        }
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            setHobspace(null);
            const e = await getEmailUser(hobspaceId);
            setEmail(e);
            const profilePicture = await getImages(e, "profile");
            setImage(profilePicture[0]);
            // Récupère les nouvelles informations utilisateur
            await getHobspaceInfo(hobspaceId, setHobspace);
        };
    
        fetchUserInfo();
    }, [hobspaceId, setHobspace]);
    
    // Utilise un autre useEffect qui s'exécute seulement lorsque user est mis à jour
    useEffect(() => {
        if (hobspace) {
            // Mets à jour les états avec les informations spécifiques
            console.log(hobspace);
            setBgdColor(hobspace.backgroundColor);
            changeBodyBackgroundColor(hobspace.backgroundColor);
            setFontColor(hobspace.colorFont);
            setDisplayEmail(hobspace.contactActive.email);
            setDisplayPhone(hobspace.contactActive.phone);
            setPhoneNumber(hobspace.phoneNumber);
    
            const pageActive = hobspace.page.map(page => ({
                id: page.id,
                name: page.name,
                component: renderComponent(page),
                icon: page.icon,
                color: page.color,
            }));
            setComponent(pageActive);
            setMenu(hobspace.page.map(component => component.title));
        }
    }, [hobspace]);
    


    return (
        <div className={styles.Home} style={{ backgroundColor: bgdColor }}>
            <TitleTabNavigator title={capitalizeFirstLetter(hobspaceId)}/>
            {!loading && hobspace ? (
                <div className={styles.containerHome} style={{ backgroundColor: bgdColor, color: fontColor }}>
                    {hobspace && (
                        <>
                            <Logo hobspace={hobspace} componentId={"Profile"} image={image} />
                            {displayEmail || displayPhone &&
                                <div className="flex flex-col items-center justify-center mb-6 gap-1">
                                    {displayEmail && <EmailButton email={email} />}
                                    {displayPhone && <CallButton phoneNumber={phoneNumber} />}
                                </div>
                            }
                            <ReseauxSociaux socialNetworks={hobspace.links} email={email} image={image} />
                            <Menu menu={menu} />
                            <div className={styles.lineSeparation}></div>
                            {component && component.map((c, index) => (
                                <React.Fragment key={index}>
                                    {c.component}
                                    {index < component.length - 1 && <div className={styles.lineSeparation}></div>}
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </div>
            ) : (<div className={styles.containerSpinner}>
                    <Loader color={fontColor} />
                </div>
                
            )}
        </div>
    );
}
    
export default Home;