import PDF from "../assets/pdf.png";
import XLS from "../assets/xls.png";
import PPT from "../assets/ppt.png";
import EBOOK from "../assets/ebook.png";
import TXT from "../assets/txt.png";


export const getFileIcon = (file) => {
    switch (file.type) {
        case "application/pdf":
            return PDF;
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/vnd.oasis.opendocument.text":
        case "application/rtf":
        case "text/plain":
            return TXT;
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "application/vnd.oasis.opendocument.spreadsheet":
        case "text/csv":
            return XLS;
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        case "application/vnd.oasis.opendocument.presentation":
            return PPT;
        case "application/epub+zip":
        case "application/x-mobipocket-ebook":
        case "application/vnd.amazon.ebook":
        case "application/x-ibooks+zip":
            return EBOOK;
        default:
            return null;
    }
};

export const getFileColor = (file) => {
    switch (file.type) {
        case "application/pdf":
            return "rgba(231, 42, 42, 0.3)";
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/vnd.oasis.opendocument.text":
        case "application/rtf":
        case "text/plain":
            return "rgba(63, 130, 239, 0.3)";
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "application/vnd.oasis.opendocument.spreadsheet":
        case "text/csv":
            return "rgba(41, 148, 56, 0.3)";
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        case "application/vnd.oasis.opendocument.presentation":
            return "rgba(235, 137, 9, 0.3)";
        case "application/epub+zip":
        case "application/x-mobipocket-ebook":
        case "application/vnd.amazon.ebook":
        case "application/x-ibooks+zip":
            return "rgba(136, 77, 255, 0.3)";
        default:
            return null;
    }
};

export const getFileExtension = (file) => {
    switch (file.type) {
        case "application/pdf":
            return ".pdf";
        case "application/msword":
            return ".doc";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return ".docx";
        case "application/vnd.oasis.opendocument.text":
            return ".odt";
        case "application/rtf":
            return ".rft";
        case "text/plain":
            return ".txt";
        case "application/vnd.ms-excel":
            return ".xls";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return ".xlsx";
        case "application/vnd.oasis.opendocument.spreadsheet":
            return ".ods";
        case "text/csv":
            return ".csv";
        case "application/vnd.ms-powerpoint":
            return ".ppt";
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            return ".pptx";
        case "application/vnd.oasis.opendocument.presentation":
            return ".odp";
        case "application/epub+zip":
            return ".epup";
        case "application/x-mobipocket-ebook":
            return ".mobi";
        case "application/vnd.amazon.ebook":
            return ".azw";
        case "application/x-ibooks+zip":
            return ".ibooks";
        default:
            return null;
    }
};

