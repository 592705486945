import React, {useState, useEffect} from 'react';
import './Profil.css';
import { Link } from "react-router-dom";
import { copyToClipboard } from '../../utils';
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../services/firebase/firebase.config';
import { useUser } from "../../utils/contexts/userContext";
import { countries } from '../../utils';

function Profil(props) {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [hobsSpace, setHobsSpace] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [country, setCountry] = useState("");
    const images = useSelector((state) => state.user.images);
    const email = useSelector((state) => state.user.email);
    const { user, loading } = useUser();

    const handleCopy = () => {
        copyToClipboard(hobsSpace, setVisible);
    };

    const handleChange = (event) => {
        setCountry(event.target.value);
    };

    useEffect(() => {
        if (user) {
            const userCountry = countries.find(c => c.code === user.country.code);
            setCountry(userCountry);
            setLastname(user.lastname);
            setFirstname(user.firstname);
            setHobsSpace(`https://hobs.space/${user.nameSpace}`);
        }
    }, [user]);

    return (
        <div className='Profil' style={{borderColor: `rgba(${props.color}, 0.3)`}}>
            <div className='containerProfil'>
                <div className={`containerImgProfil ${images !== undefined ? "" : "active"}`}>
                    {images !== undefined ? 
                        <img src={images} className='imgUserProfil' alt='profile' />
                    :
                        <i style={{color: "#fff"}} className={"fa-regular fa-user"}></i>
                    }
                </div>
                <div className='containerTextProfil'>
                    <div className='containerInfoUser'>
                        <div className='countryProfil'>
                            <ReactCountryFlag
                                countryCode={country.code}
                                svg
                                style={{
                                    width: '15px',
                                    height: '10px',
                                    borderRadius: '3px',
                                    objectFif: "cover",
                                }}
                                title={country.name}
                            />
                            <p className='nameCountryProfil'>france</p>
                        </div>
                        <p className='nameUserProfil'>{firstname} {lastname}</p>
                        <p className='emailUserProfil'>{email}</p>
                    </div>
                    <div className='profilLine'></div>
                    <div className='containerHobsSpaceUserProfil'>
                        <div className='containerTopHobsSpaceUserProfil'>
                            <p className='titleHobsSpaceUserProfil'>Hob's Space</p>
                            <div className='containerCustomUrlProfil'>
                                <Link className='linkUrlProfil' to={''}>Personnaliser l'url</Link>
                                <div className='iconButtonUrlProfil'>
                                    <i style={{color: "#ff8500"}} className={"fa-solid fa-arrow-right"}></i>
                                </div>
                            </div>
                        </div>
                        <div className='containerBttomHobsSpaceUserProfil'>
                            <button className='containerUrlUserProfil' onClick={handleCopy}>
                                <div className='iconButton'>
                                    <i style={{color: "#ff8500"}} className={"fa-solid fa-link"}></i>
                                </div>
                                <p className='linkUrlHobsSpace'>{hobsSpace}</p>
                                <p className={`textCopy ${visible ? 'visible' : ''}`}>Url Copié</p>
                            </button>
                            <button className='shareUrlHobsSpace'>
                                <div className='iconButton'>
                                    <i style={{color: "#fff"}} className={"fa-solid fa-paper-plane"}></i>
                                </div>
                                <p className='textShareUrlHobsSpace'>partager l'url</p>
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Profil;
