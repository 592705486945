import React from 'react';
import SectionButtonAdd from '../../components/SectionButtonAdd/SectionButtonAdd';
import YoutubeShort from "../../assets/youtube-short.png";
import Youtube from "../../assets/youtube-color.png";
import Tiktok from "../../assets/tiktok-color.png";
import Wavaform from "../../assets/waveform.png";
import Type from "../../assets/type.png";
import TicketLogo from "../../assets/ticket.png";
import Note from "../../assets/note.png";
import LinkDuonote from "../../assets/link.png";
import ImgMedia from "../../assets/image.png";
import Linkedin from "../../assets/linkedin-color.png"
import Currency from "../../assets/currency-coin-euro.png";
import File from "../../assets/file.png";
import Bag from "../../assets/bag.png";

const AddContent = (props) => {

  return (
    <div className='flex flex-col justify-start items-start p-10 bg-slate-200 overflow-y-scroll scrollbarhide h-full' style={{ width: "100%", height: "100%" }}>
      <div className='flex flex-row justify-center items-center gap-3 pb-8 w-fit'>
        <button className='flex justify-center items-center' onClick={props.setOpenModal}>
          <i style={{ color: "#000", fontSize: "22px" }} className="fa-solid fa-arrow-left"></i>
        </button>
        <p className='text-xl text-black font-bold'>Ajoutez du contenu à votre page</p>
      </div>

      <SectionButtonAdd
        title="Lien"
        items={[
          { imgSrc: LinkDuonote, title: 'Lien externe', description: 'Lier un site web externe', onClick: () => props.addComponent('Links'), active: true },
          { imgSrc: ImgMedia, title: 'Galerie médias', description: 'Présentez vos photos et vidéos', onClick: () => props.addComponent('Gallery'), active: true },
        ]}
      />
      <SectionButtonAdd
        title="Social"
        items={[
          { imgSrc: Linkedin, title: 'Linkedin', description: 'Mettez en avant votre compte Linkedin', onClick: () => props.addComponent('LinkedinAccount'), active: true },
        ]}
      />
      <SectionButtonAdd
        title="Produit"
        items={[
          { imgSrc: Bag, title: 'Produit personnalisé', description: 'Lier les produits à l\'extérieur', onClick: () => props.addComponent('Product'), active: false },
        ]}
      />
      <SectionButtonAdd
        title="Audio"
        items={[
          { imgSrc: Note, title: 'Musiques', description: 'Ajouter une ou plusieurs musiques', onClick: () => props.addComponent('Music'), active: false },
          { imgSrc: Wavaform, title: 'Podcast', description: 'Ajouter un ou plusieurs podcasts', onClick: () => props.addComponent('Podcast'), active: false },
        ]}
      />
      <SectionButtonAdd
        title="Vidéo"
        items={[
          { imgSrc: Youtube, title: 'Vidéo individuelle', description: 'Ajouter une ou plusieurs vidéos', onClick: () => props.addComponent('IndividualVideo'), active: true },
          { imgSrc: Youtube, title: 'Collection de vidéos', description: 'Ajouter vos dernières vidéos', onClick: () => props.addComponent('VideoCollection'), active: true },
          { imgSrc: YoutubeShort, title: 'Youtube shorts', description: 'Ajouter des shorts ou chaînes', onClick: () => props.addComponent('YoutubeShort'), active: false },
          { imgSrc: Tiktok, title: 'TikTok', description: 'Ajouter des vidéos TikTok', onClick: () => props.addComponent('TikTok'), active: false },
        ]}
      />
      <SectionButtonAdd
        title="Contenu"
        items={[
          { imgSrc: Type, title: 'Texte', description: 'Ajouter une zone de texte', onClick: () => props.addComponent('Text'), active: true },
          { imgSrc: File, title: 'Document', description: 'Permettez de télécharger des fichiers', onClick: () => props.addComponent('Files'), active: true },
        ]}
      />
      <SectionButtonAdd
        title="Évènement"
        items={[
          { imgSrc: TicketLogo, title: 'Événement personnalisé', description: 'Lier un événement externe', onClick: () => props.addComponent('Event'), active: true },
        ]}
      />
    </div>
  );
};

export default AddContent;
