import React, {useState, useEffect} from 'react'
import './NavigationDashboard.css';
import ButtonMenu from '../../../components/ButtonMenu/ButtonMenu';
import { Link } from "react-router-dom";
import HobsPage from '../../DashboardPages/HobsPage/HobsPage';
import MailSignature from '../../DashboardPages/MailSignature/MailSignature';
import Tutorial from '../../DashboardPages/Tutorial/Tutorial';
import Statistics from '../../DashboardPages/Statistics/Statistics';
import Settings from '../../DashboardPages/Settings/Settings';
import QrCodeGenerator from '../../DashboardPages/QrCodeGenerator/QrCodeGenerator';
import  {useSelector, useDispatch} from "react-redux";
import { pageDashboard } from '../../../services/store/reducers/userSlice';
import { logout } from '../../../services/auth/authService';
import Logo from '../../../assets/logo.png';
import { useUser } from "../../../utils/contexts/userContext";
import { getImages } from '../../../services/storage/storageService';
import { setImages } from '../../../services/store/reducers/userSlice';

function NavigationDashboard(props) {
    const page = useSelector((state) => state.user.pageDashboard);
    const [showUserProfileMenu, setShowUserProfileMenu] = useState(false);
    const { user, loading } = useUser();
    //const [images, setImages] = useState([]);
    const images = useSelector((state) => state.user.images);
    const email = useSelector((state) => state.user.email);
    const imgChange = useSelector((state) => state.user.uploadImage);
    const dispatch = useDispatch();

    const toggleUserProfileMenu = () => {
        setShowUserProfileMenu(!showUserProfileMenu);
    };

    const handleLogout = async () => {
        await logout(dispatch);
    };

    const handleButtonClick = (title) => {
        dispatch(pageDashboard(title));
    };

    return (
        <>
        <div className='NavigationDashboard' style={{borderColor: `rgba(${props.color}, 0.3)`}}>
            <div className='containerTitleDashboard'>
                <h1 className='titleDashboard'>{page}</h1>
                {!loading && user ? (
                    <button className='linkNavigationProfil signup' onClick={toggleUserProfileMenu}>
                        {images !== undefined ? 
                                <img className='imgProfilNavigation' src={images} alt="profil" />
                            :
                                <div className='imgProfilNavigation'>
                                    <i className={"fa-regular fa-user"}></i>
                                </div>
                            }
                        <p className='nameProfilNavigation'>{user.nameSpace}</p>
                    </button>
                ) : (
                    null
                )}
            </div> 
        </div>
        <div className='containerMenuDashboard'>
            <Link to={"/"} className='containerLogoDashboard' >
                <img className='logo logoDashboard' src={Logo} alt="logo hob's space retour accueil" />
            </Link>
            <br />
            <ButtonMenu title={"Mon Hob's Space"} active={page === "Mon Hob's Space" ? true : false} onClick={() => handleButtonClick("Mon Hob's Space")} color={props.color} icon={"fa-solid fa-link"}/>
            <ButtonMenu title={"Mon QR Code"} active={page === "Mon QR Code" ? true : false} onClick={() => handleButtonClick("Mon QR Code")} color={props.color} icon={"fa-solid fa-qrcode"}/>
            {/* <ButtonMenu title={"Signature de mail"} active={page === "Signature de mail" ? true : false} onClick={() => handleButtonClick("Signature de mail")} color={props.color} icon={"fa-solid fa-envelope"} />
            <ButtonMenu title={"Tutoriel"} active={page === "Tutoriel" ? true : false} onClick={() => handleButtonClick("Tutoriel")} color={props.color} icon={"fa-solid fa-circle-play"} /> */}
            {/* <ButtonMenu title={"Statistiques"} active={page === "Statistiques" ? true : false} onClick={() => handleButtonClick("Statistiques")} color={props.color} icon={"fa-solid fa-chart-simple"} /> */}
            {/* <ButtonMenu title={"Settings"} active={page === "Settings" ? true : false} onClick={() => handleButtonClick("Settings")} color={props.color} icon={"fa-solid fa-gear"} />*/}
        </div> 
       
        {page === "Mon Hob's Space" ? (<HobsPage color={props.color} bgd={props.bgd} />):(null)}
        {page === "Mon QR Code" ? (<QrCodeGenerator color={props.color} bgd={props.bgd} />):(null)}
        {page === "Signature de mail" ? (<MailSignature color={props.color} bgd={props.bgd} />):(null)}
        {page === "Tutoriel" ? (<Tutorial color={props.color} bgd={props.bgd} />):(null)}
        {page === "Statistiques" ? (<Statistics color={props.color} bgd={props.bgd} />):(null)}
        {page === "Settings" ? (<Settings color={props.color} bgd={props.bgd} />):(null)}
        {!loading && user &&
            <div className={`userProfileMenu ${showUserProfileMenu ? "active" : ""}`}>
                <button className="closeButton" onClick={toggleUserProfileMenu}>X</button>
                <div className="userInfo">
                    {images !== undefined ? 
                        <img src={images} alt="Profile" className="profilePicture" />
                    :
                        <div className='profilePicture'>
                            <i className={"fa-regular fa-user"}></i>
                        </div>
                    }
                    <p className="userName">{user.nameSpace}</p>
                </div>
                <Link to='/' className="logoutButton" onClick={handleLogout}>Se déconnecter</Link>
                <Link to="/dashboard" className="manageProfileButton">Gérer le profil</Link>
                {/* Ajoutez d'autres options de profil ici */}
            </div>
        }
        </>
    );
}

export default NavigationDashboard;