import React from 'react'
import './Title.css';
import { useSelector } from 'react-redux';

function Title(props) {
    const color = useSelector((state) => state.user.colorFont);
 
    return (
        <p className="Title" id={props.id} style={{color: color, marginBottom: !props.marginBottom ? "10px" : ""}}>
            {props.title}
        </p>
    );
}

export default Title;