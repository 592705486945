import React, {useEffect} from 'react'
import Question from "../../components/Question/Question"

import './FAQ.css';

function FAQ() {

    useEffect(() => {
    }, [])

    return (
        <section className="section-faq">
            <h1 className='faq-title'>Foire aux questions</h1>
            <div className='container-questions'>
                <Question title={"Qu'est-ce que HOBSPACE ?"} answer={"HOBSPACE est une plateforme qui vous permet de regrouper et de partager toutes vos informations professionnelles et personnelles importantes via un lien unique ainsi que de partager vos contacts. Cela inclut vos réseaux sociaux, portfolios, projets, vidéos, et bien plus encore."}></Question>
                <Question title={"Comment puis-je créer mon lien unique HOBSPACE ?"} answer={"La création de votre lien unique est simple. Inscrivez-vous sur notre plateforme, accédez à votre tableau de bord, ajoutez vos liens et contenus, personnalisez votre page, et générez votre lien unique."}></Question>
                <Question title={"Quels types de contenu puis-je ajouter à mon HOBSPACE ?"} answer={"Vous pouvez ajouter une variété de contenus, tels que des sites internet, des profils de réseaux sociaux, des références de projets, des vidéos, des offres d’emploi, des portfolios, des podcasts, des boutiques en ligne, des formulaires de contact, des webinaires et des démonstrations de produits."}></Question>
                <Question title={"Comment puis-je partager mon lien HOBSPACE ?"} answer={"Vous pouvez partager votre lien unique via un QR code, en l'intégrant à votre signature d'email, ou en le publiant sur vos profils de réseaux sociaux et autres plateformes de communication."}></Question>
                <Question title={"Est-ce que HOBSPACE nécessite des compétences techniques pour être utilisé ?"} answer={"Non, HOBSPACE est conçu pour être simple et intuitif. Notre tableau de bord convivial ne nécessite aucune compétence technique et vous permet de personnaliser votre page facilement."}></Question>
                <Question title={"Puis-je personnaliser l'apparence de ma page HOBSPACE ?"} answer={"Oui, vous pouvez personnaliser l'apparence de votre page pour qu'elle reflète votre identité de marque, en ajustant les couleurs, les polices, et les dispositions de contenu selon vos préférences."}></Question>
                <Question title={"Comment puis-je suivre la performance de mes liens ?"} answer={"HOBSPACE offre des outils de suivi intégrés qui vous permettent de surveiller les performances de vos liens. Vous pouvez voir le nombre de clics, les sources de trafic, et d'autres statistiques pour mesurer l'efficacité de vos campagnes."}></Question>
                <Question title={"Est-ce que HOBSPACE est sécurisé ?"} answer={"Oui, nous prenons la sécurité de vos données très au sérieux. HOBSPACE utilise des protocoles de sécurité avancés pour garantir que vos informations restent protégées."}></Question>
                <Question title={"HOBSPACE est-il compatible avec tous les appareils ?"} answer={"Oui, HOBSPACE est conçu pour être responsive et fonctionne parfaitement sur les ordinateurs de bureau, les tablettes et les smartphones."}></Question>
                <Question title={"Comment HOBSPACE peut-il aider à améliorer mon expérience client ?"} answer={"En regroupant toutes vos informations importantes en un seul lien facile à partager, HOBSPACE simplifie l'accès pour vos clients, améliore leur expérience utilisateur, et vous aide à communiquer de manière plus efficace et professionnelle."}></Question>
                <Question title={"Puis-je utiliser HOBSPACE pour des besoins internes de mon entreprise ?"} answer={"Absolument. HOBSPACE peut être utilisé pour regrouper des liens utiles pour vos employés, faciliter la communication interne, et partager des contenus et événements importants qui mettent en avant la culture de l'entreprise."}></Question>
                <Question title={"Quels sont les avantages de HOBSPACE pour les départements de communication et marketing ?"} answer={"HOBSPACE centralise tous vos liens importants, facilite le suivi des performances de vos campagnes, améliore l'accès aux informations sur les réseaux sociaux, et permet de personnaliser votre interface pour refléter votre identité de marque."}></Question>
                <Question title={"Comment HOBSPACE peut-il aider les départements financiers ?"} answer={"HOBSPACE permet de centraliser tous les liens vers des rapports financiers, des analyses de marché, des documents de conformité et des outils de gestion en un seul endroit, simplifiant ainsi l'accès pour les membres de l'équipe et les parties prenantes externes."}></Question>
                <Question title={"Quels sont les plans tarifaires disponibles pour HOBSPACE ?"} answer={"Nous proposons plusieurs plans tarifaires adaptés à différents besoins, allant des options gratuites pour les utilisateurs individuels aux solutions premium pour les entreprises. Consultez notre page de tarification pour plus de détails."}></Question>
                <Question title={"Comment puis-je contacter le support client de HOBSPACE ?"} answer={"Vous pouvez nous contacter via notre formulaire de contact sur le site web, par email à support@hobspace.com, ou via notre chat en ligne pour une assistance rapide et efficace."}></Question>

            </div>
        </section>
    );
}

export default FAQ;