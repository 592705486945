import React from 'react';
import PropTypes from 'prop-types';
import "./EmailButton.css";
import { useUser } from "../../utils/contexts/userContext";

function EmailButton({ email, subject, body }) {
    const { user } = useUser();
    const handleEmailClick = () => {
        window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };

    return (
        <button onClick={handleEmailClick} className='buttonDisplayEmailPhone'>
            <div className='iconButton'>
                <i style={{color: user.colorFont}} className={"fa-solid fa-envelope"}></i>
            </div>
            <p style={{color: user.colorFont}}>{email}</p>
        </button>
    );
}

EmailButton.propTypes = {
    email: PropTypes.string.isRequired,
    subject: PropTypes.string,
    body: PropTypes.string,
    children: PropTypes.node,
};

EmailButton.defaultProps = {
    subject: '',
    body: '',
    children: null,
};

export default EmailButton;
