import React from 'react'


function ButtonCardAdd(props) {
 
    return (
        <button onClick={props.active ? props.onClick : () => {}} className='relative flex flex-row w-72 h-24 justify-start items-center gap-3 bg-white drop-shadow-md p-3 rounded-xl cursor-pointer hover:-translate-y-2 transition ease-in-out duration-300'>
            <img src={props.img} className='h-9 w-9 object-center object-contain' />
            <div className='flex flex-col justify-center items-start'>
            <p className='text-left text-md font-bold capitalize'>{props.title}</p>
            <p className='text-left text-xs'>{props.description}</p>
            </div>
            {!props.active &&
                <p className=' absolute py-1 px-2 text-white rounded-full' style={{top: "-5px", right: "-10px", fontSize: "10px", backgroundColor: "#ff8500"}}>coming soon</p>
            }
        </button>
    );
}

export default ButtonCardAdd;