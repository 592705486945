import React, { useState, useEffect } from 'react';
import './SignUp.css';
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Google from "../../assets/google.png";
import Apple from "../../assets/apple.png";
import { setOffer, setHobsSpace } from '../../services/store/reducers/userSlice';
import { checkPasswordStrength, createUserWithEmailAndPassword, checkEmail, checkHobsSpaceExists } from '../../services/auth/authService';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import {useSelector, useDispatch} from "react-redux"
import { useNavigate } from 'react-router-dom';
import ConfigProfile from './ConfigProfile';
import '@fortawesome/fontawesome-free/css/all.css';
import { emailIsValid, hobsSpaceIsValid, passwordIsValid } from '../../services/store/reducers/authSlice';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';

function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hobsspace, setHobsspace] = useState("");
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const isChecked = useSelector((state) => state.user.offers);
    const error = useSelector((state) => state.auth.passwordIsValid);
    const errorEmail = useSelector((state) => state.auth.emailIsValid);
    const errorHobs = useSelector((state) => state.auth.hobsSpaceIsValid);
    const errorMessage = useSelector((state) => state.auth.errorMessage);
    const [conf, setConf] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const login = useSelector((state) => state.user.login);

    const handleChangeOffer = () => {
        dispatch(setOffer(!isChecked))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Vérifier si l'username est un email ou un numéro de téléphone
        const emailVerif = await checkEmail(email, dispatch);
        if(emailVerif) {
            const passwordVerif = checkPasswordStrength(password, dispatch);
            if(passwordVerif) {
                const hobsSpaceVerif = await checkHobsSpaceExists(hobsspace, dispatch); 
                if(hobsSpaceVerif){
                    dispatch(setHobsSpace(hobsspace));
                    //await createUserWithEmailAndPassword(email, password, hobsspace, {offer: isChecked, nameSpace: hobsspace}, dispatch);
                    setConf(true);
                }
            }
        }
    };

    const createUser = async (data, image) => {
        try {
            // Appel à la fonction pour créer un utilisateur
            const userCredential = await createUserWithEmailAndPassword(
                email, 
                password, 
                hobsspace, 
                data, 
                dispatch, 
                image
            );
            // Vérifie si un utilisateur a bien été créé
            if (userCredential) {
                console.log("Utilisateur créé avec succès:");
                navigate("/dashboard");
            } else {
                throw new Error("La création de l'utilisateur a échoué.");
            }
        } catch (error) {
            // Gestion de l'erreur : affichage ou enregistrement
            console.error("Erreur lors de la création de l'utilisateur:", error.message);
            alert("Erreur : " + error.message);
        }
    };
    

    useEffect(() => {
        dispatch(passwordIsValid({isValid: null, message: ""}));
        dispatch(emailIsValid({isValid: null, message: ""}));
        dispatch(hobsSpaceIsValid({isValid: null, message: ""}));
        dispatch(setOffer(false));
        dispatch(setHobsSpace(""));
     }, []);

    return (
        <>
        <TitleTabNavigator title={"Inscription"}/>
        {conf ? 
            <ConfigProfile createUser={createUser} />
        :
            <div className='Login'>
                <Link className='containerLogoLogin' to='/'><img className='logoLogin' src={Logo} alt="logo hobs space retour page d'accueil"/></Link>
                <div className='sectionSignup'>
                    <div className='containerLogin'>
                        <div className='containerTitleLogin'>
                            <h1 className='titleLogin'>Rejoindre Hob's Space</h1>
                            <p className='subtitleLogin'>Inscrivez-vous gratuitement à Hob's Space</p>
                        </div>
                        <div className="login-container">
                            <form onSubmit={handleSubmit} className="signup-form">
                                <div className="form-group-login user">
                                    <input id="email" type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                    {errorEmail === false && 
                                        <ErrorMessage message={errorMessage} error={!errorEmail} />
                                    }
                                </div>
                                <div className="form-group-login mt-5">
                                    <input type={passwordIsVisible ? "text" : "password"} placeholder='Mot de passe' value={password} onChange={(e) => setPassword(e.target.value)} required />
                                    <button type="button" className='buttonEyesPassword' onClick={() => setPasswordIsVisible(!passwordIsVisible)}>
                                        {passwordIsVisible ? (
                                            <i className="fa-solid fa-eye"></i>
                                        ):(
                                            <i className="fa-solid fa-eye-slash"></i>
                                        )}
                                    </button>
                                    {error === false && 
                                        <ErrorMessage message={errorMessage} error={!error} />
                                    }
                                </div>
                                <div className="form-group-login mt-5">
                                    <label className="labelSignUpHobs" for="hobs">hobs-space/</label>
                                    <input id='hobs' type="text" value={hobsspace} onChange={(e) => setHobsspace(e.target.value)} required />
                                    {errorHobs === false && 
                                        <ErrorMessage message={errorMessage} error={!errorHobs} />
                                    }
                                </div>
                                <div className="containerCheckboxSignup" onClick={handleChangeOffer}>
                                    <input className="inputCheckboxSelectableComponent" type="checkbox" checked={isChecked} onChange={() => {}} />
                                    <span className={isChecked ? 'checkmark checked' : 'checkmark'} />   
                                    <span className="checkbox-label">J'accepte de recevoir des offres, des nouvelles et des mises à jour de Hob's Space.</span>
                                </div>
                                <button type="submit" className='buttonSubmitLogin'>Créez votre compte</button>
                                <div className="signup-link signup">
                                    <p>En cliquant sur Créer un compte, vous acceptez les <Link to='' className='signupLogin'>termes et conditions générales</Link> de Hob's Space et confirmez que vous avez lu notre avis de <Link to='' className='signupLogin'>confidentialité</Link>.</p>
                                </div>
                            </form>
                            <div className='ligneLogin'></div>
                            {/* <div className="divider">
                                <span>OU</span>
                            </div>
                            <div className="social-login">
                                <button className="google-btn"><img className='iconSocialLoginBtn' src={Google} alt='logo google connexion'/>S'inscrire avec Google</button>
                                <button className="apple-btn"><img className='iconSocialLoginBtn' src={Apple} alt='logo apple connexion'/>S'inscrire avec Apple</button>
                            </div> */}
                            <div className="signup-link">
                                <p>Vous avez déjà un compte ? <Link to='/login' className='signupLogin'>Connectez-vous</Link></p>
                            </div>
                            <div className="signup-link signupTerm">
                                <p>Ce site est protégé par reCAPTCHA et les <Link to='' className='signupLogin'>règles de confidentialité</Link> et les <Link to='' className='signupLogin'>conditions d'utilisation de Google</Link> s'appliquent.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='containerImgLogin'>
                    <div className='backgroundImgLogin'></div>
                </div> 
            </div>
        }
        </>
        
    );
}

export default SignUp;
