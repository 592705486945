
import React, { useEffect, useState, useCallback } from 'react';
import './OneVideo.css';
import { getYoutubeVideo, getLatestYoutubeVideoId, getYoutubeVideoId } from '../../services/api/youtube';
import { useUser } from '../../utils/contexts/userContext';
import { useSelector } from 'react-redux';

function OneVideo(props) {
    const [description, setDescription] = useState("");
    const [videoId, setVideoId] = useState("");
    const { user } = useUser();
    const color = useSelector((state) => state.user.colorFont);

    // Fonction pour charger la vidéo
    const loadVideo = useCallback(async () => {
        try {
            const id = await getYoutubeVideoId(props.idVideo, user); // Récupération de l'ID de la vidéo
            setVideoId(id);
            if (id) {
                const video = await getYoutubeVideo(id); // Récupération des informations de la vidéo
                if (video.snippet.description !== "") {
                    const descriptionLines = video.snippet.description.split('\n\n');
                    const selectedDescription = descriptionLines.slice(0, 2).join('\n\n');
                    setDescription(selectedDescription); // Mise à jour de la description
                }
            } else {
                console.error("Impossible de charger la vidéo. ID invalide.");
            }
        } catch (error) {
            console.error("Erreur lors du chargement de la vidéo:", error);
        }
    }, [props.idVideo]);

    useEffect(() => {
        loadVideo();
    }, [loadVideo]);

    return (
        <div className="SectionOneVideo">
            {/* <Title id={props.ancre} title={props.title} marginBottom={false} /> */}
            {props.description ? (
                <div className="containerOneVideoDescript">
                    <div className="OneVideoDescript">
                        <iframe 
                            className="Oneminiature" 
                            src={`https://www.youtube.com/embed/${videoId}`} 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowFullScreen>
                        </iframe>
                    </div>
                    <p style={{ color: color }} className="descriptionOneVideo">
                        {description}
                    </p>
                </div>
            ) : (
                <div className="OneVideo">
                    <iframe 
                        className="Oneminiature" 
                        src={`https://www.youtube.com/embed/${videoId}`} 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen>
                    </iframe>
                </div>
            )}
        </div>
    );
}

export default OneVideo;
