import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import './DragAndDropContainer.css';
import ButtonEditUnderComponent from '../ButtonEditComponent/ButtonEditUnderComponent';
import { Trash2, GripVertical } from 'lucide-react';

function DragAndDropContainer({ initialContainers, deleteBlock, onReorder, editComponent, droppableId }) {
    const [containers, setContainers] = useState(initialContainers);
    const [visibility, setVisibility] = useState(true);

    useEffect(() => {
        setContainers(initialContainers);
    }, [initialContainers]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(containers);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setContainers(items);
        if (onReorder) {
            onReorder(items, droppableId);
        }
    };
    
    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId={droppableId}>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="container-list"
                    >
                        {containers && containers.map((container, index) => (
                            <Draggable key={container.id} draggableId={container.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className={`draggable-container ${snapshot.isDragging ? 'dragging' : ''}`}
                                    >
                                        <div className='flex flex-row gap-0.5 cursor-grab' {...provided.dragHandleProps}>
                                            <GripVertical color="#475569" />
                                        </div>

                                        {/* Exécuter renderContent si c'est une fonction */}
                                        <div className='textDragAndDrop'>
                                            {typeof container.renderContent === 'function'
                                                ? container.renderContent() // Exécution de la fonction pour rendre le contenu
                                                : container.renderContent}
                                        </div>

                                        <div className='containerButtonDragAndDrop'>
                                            <button className='buttonDragAndDrop' onClick={() => setVisibility(!visibility)}>
                                                {visibility ? (
                                                    <i className="fa-solid fa-eye" style={{color: "#000"}}></i>
                                                ):(
                                                    <i className="fa-solid fa-eye-slash" style={{color: "#000"}}></i>
                                                )}
                                            </button>
                                            <ButtonEditUnderComponent obj={container} />
                                            <button className='buttonDragAndDrop' onClick={() => deleteBlock(container.id)}>
                                                <Trash2 />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default DragAndDropContainer;
