import React, { useState, useEffect } from 'react'
import styles from './VideoCollection.module.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import OneVideo from '../OneVideo/OneVideo';
import { getLatestYoutubeVideos } from '../../../services/api/youtube';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function VideoCollection(props) {
    const {hobspace} = useHobspace();
    const [videos, setVideos] = useState(undefined);
  
    useEffect(() => { 
      async function fetchData() {
        const v = await getLatestYoutubeVideos(hobspace, props.nbVideo);
        if (v === null) {
          return;
        }
        setVideos(v.map((video, index) => (
          <OneVideo
            key={index}
            idVideo={`https://www.youtube.com/watch?v=${video.id.videoId}`}
            description={false}
          />
        )));
      }

      fetchData();
    }, [props.nbVideo])
 
    return (
        <div className={styles.VideoCollection}>
            {props.title &&
                <Title id={props.ancre} color={hobspace.colorFont} title={props.title} marginBottom={true} />
            }
            {videos !== undefined && <Slider components={videos} />}
        </div>
    );
}

export default VideoCollection;