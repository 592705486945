import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './InputField.css';
import { useUser } from '../../utils/contexts/userContext';

function InputPhoneNumber({ value, onChange, title, placeholder, required, country }) {
    const [isFocused, setIsFocused] = useState(false);
    
    const handlePhoneChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className={`InputFieldText ${isFocused ? 'focused' : ''} text-xl`}>
            {title && <p className='InputFieldTextTitle'>{title}</p>}
            <PhoneInput
                country={country.toLowerCase()}
                value={value}
                onChange={handlePhoneChange}
                inputProps={{
                    name: 'phone',
                    required: required,
                    autoFocus: false,
                    placeholder: placeholder
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                containerClass="InputPhoneNumberContainer"
                inputClass="InputFieldTextInput"
            />
        </div>
    );
}

InputPhoneNumber.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
};

InputPhoneNumber.defaultProps = {
    title: '',
    placeholder: '',
    value: '',
    required: false,
};

export default InputPhoneNumber;
