import React, {useState} from 'react'
import './Statistics.css';

function Statistics(props) {
 
    return (
        <div className='Statistics'>
            
        </div>
    );
}

export default Statistics;