import React from 'react'
import './Menu.css';
import AncreMenu from '../AncreMenu/AncreMenu';
import { useSelector } from 'react-redux';

function Menu(props) {

    const menu = useSelector((state) => state.user.menu);

    return (
        <div className='Menu'>
            {menu ? menu.map((title, index) =>
                <AncreMenu key={index} ancre={title} name={title} />
            )
            :
            null
            }
        </div>
    );
}

export default Menu;