import React, {useState, useEffect} from 'react'
import styles from './Link.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import { getImageComponent } from '../../../services/storage/storageService';

function Link(props) {
    const {hobspace} = useHobspace();
    const [image, setImage] = useState(null);

    useEffect(() => {
        const fetchMedia = async () => {
            if (props.id) {
                try {
                    // Utilisation de la fonction pour récupérer le média
                    const url = await getImageComponent(props.email, 'Link', props.id);
                    setImage(url); // Mettre à jour la prévisualisation avec l'URL récupérée
                } catch (error) {
                    console.error('Erreur lors de la récupération du média:', error);
                    setImage(null); // Réinitialiser si une erreur se produit
                }
            }
        };

        fetchMedia();
    }, [props.id, props.email]); 
 
    return (
        <a className={styles.Link} href={props.link} style={{color: hobspace.colorFont}}>
            {image === null ?
                <div className={styles.containerImageDefault}>
                    <i className="fa-solid fa-image"></i>
                </div>
                :
                <img className={styles.imgTicket} src={image} alt="icon produit" />
            }
            
            <div className={styles.infoTicket}>
                <p style={{color: hobspace.colorFont}} className={`${styles.titleTicket} text-xl max-md:text-lg max-425:text-base max-350:text-sm line-clamp-2`}>{props.title}</p>
                {props.subtitle !== "" ? <p style={{color: hobspace.colorFont}} className={`${styles.priceTicket} text-lg max-md:text-base max-425:text-sm max-350:text-xs line-clamp-2`}>{props.subtitle}</p> : null}
            </div>
        </a>

    );
}

export default Link;