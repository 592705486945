import React from 'react';
import './SliderHobspace.css';
import { Navigation, Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

function Slider(props) {

    const lengthComponentsAvg = Math.ceil(((props.components).length) / 2);

    return (
        <div className={`SliderHobspace`}>
            {props.column ? 
                <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={24}
                    navigation={true}
                    modules={[Grid, Navigation]}
                    className="mySwiperHobspace"
                >
                    {props.components.slice(0, lengthComponentsAvg).map((component, index) => (
                        <SwiperSlide key={`first-${index}`}> {/* Utilisation d'une clé unique */}
                            <div className={`sliderColumnHobspace`}>
                                {component}
                                {props.components.slice(lengthComponentsAvg)[index] && (
                                    <React.Fragment key={`second-${index}`}>
                                        {props.components.slice(lengthComponentsAvg)[index]}
                                    </React.Fragment>
                                )}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                :
                <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={24}
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiperHobspace"
                >
                    {props.components.map((component, index) => (
                        <SwiperSlide key={`single-${index}`}> {/* Utilisation d'une clé unique */}
                            {component}
                        </SwiperSlide>
                    ))}
                </Swiper>
            } 
        </div>
    );
}

export default Slider;
