import React from 'react'
import styles from './ImageProfile.module.css';

function ImageProfile(props) {

 
    return (
        <div className={styles.ImageProfile}>
            <div className={`${styles.containerCover} ${props.format ? styles.vertical : styles.horizontal}`}>
                <img src={props.img} alt="cover personnalité" />
            </div>
        </div>
    );
}

export default ImageProfile;