import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./reducers/userSlice";
import { authSlice } from "./reducers/authSlice";
import { notifySlice } from "./reducers/notifySlice";


export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        auth: authSlice.reducer,
        notify: notifySlice.reducer,
    }
})

