import React from 'react'
import styles from './LinkedinAccount.module.css';
import Title from '../Title/Title';
import LinkedinProfil from '../LinkedinProfil/LinkedinProfil';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function LinkedinAccount(props) {
  const {hobspace} = useHobspace();

    return (
      <div className={styles.LinkedinAccount}>
        {props.title &&
          <Title id={props.ancre} color={hobspace.colorFont} title={props.title} marginBottom={true} />
        }
        <LinkedinProfil description={props.description} image={props.image} />
      </div>
    );
}

export default LinkedinAccount;