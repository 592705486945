import React, {useState} from 'react'
import './MailSignature.css';

function MailSignature(props) {

   
    return (
        <div className='MailSignature'>

        </div>
    );
}


export default MailSignature;