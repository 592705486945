import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../services/firebase/firebase.config';
import Hobspace from "../../services/storage/hopspace.js";

const HobspaceContext = createContext();

export const useHobspace = () => useContext(HobspaceContext);

export const HobspaceProvider = ({ children }) => {
  const [hobspace, setHobspace] = useState(null);  // Ajouter setUser
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userDocRef = doc(db, 'users', firebaseUser.email);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const hobspaceData = userDocSnap.data();
          const hobspaceInstance = Hobspace.fromFirestore(hobspaceData);
          setHobspace(hobspaceInstance);
        } else {
          setHobspace(null);
        }
      } else {
        setHobspace(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <HobspaceContext.Provider value={{ hobspace, setHobspace, loading }}>  {/* Ajout de setUser */}
      {children}
    </HobspaceContext.Provider>
  );
};
