import  { createSlice } from "@reduxjs/toolkit";

const initialState = {
    type: true,
    status: false,
    message: "",
}

export const notifySlice = createSlice({
    name: "notify",
    initialState,
    reducers: {
        setNotify(state, action) {
            state.type = action.payload.type;
            state.status = true; 
            state.message = action.payload.message;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
    }
})

export const {setNotify, setStatus} = notifySlice.actions;