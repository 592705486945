import React, { useState, useEffect } from 'react';
import "./Dashboard.css";
import SpaceName from '../../components/SpaceName/SpaceName';
import Cover from '../../assets/logoIcon.png';
import NavigationDashboard from '../../Layouts/Navigation/NavigationDashboard/NavigationDashboard';
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../assets/logo.png";
import { listenToAuthState } from '../../services/auth/authService';
import { useUser } from "../../utils/contexts/userContext";
import { logout } from '../../services/auth/authService';
import Loader from "../../components/Loader/Loader";
import { Link } from 'react-router-dom';
import useScreenWidth from '../../utils/hook/useScreenWidth';
import Notify from '../../components/Notify/Notify';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import Profil from '../../Layouts/Profil/Profil';

function Dashboard() {
    const bgdContainer = "#fff";
    const colorFont = "255, 255, 255";
    const email = useSelector((state) => state.user.email);
    const [showUserProfileMenu, setShowUserProfileMenu] = useState(false);
    const { user, loading } = useUser();
    const dispatch = useDispatch();
    const screenWidth = useScreenWidth();
    const image = useSelector((state) => state.user.images);

    const toggleUserProfileMenu = () => {
        setShowUserProfileMenu(!showUserProfileMenu);
    };

    const handleLogout = async () => {
        await logout(dispatch);
    };

    useEffect(() => {
        listenToAuthState(dispatch, image);
    }, [dispatch]);

    return (
        <>
            <TitleTabNavigator title={"Dashboard"}/>
            {screenWidth >= 1000 ? 
                <div className='Dashboard' style={{background: bgdContainer}}>
                    {/* <SpaceName imgProfil={Cover} spacename={spacename} color={colorFont}/> */}
                    {!loading && user ?        
                        <NavigationDashboard color={colorFont} bgd={bgdContainer} />
                    :
                        <Loader />
                    }
                    <Notify/>
                </div>
            : 
                <div className='sectionNoDashboard'>
                    <div className='containerNoDashboard'>
                        <h1 className='titleNoDashboard'>Dashboard bientôt sur mobile</h1>
                        <p className='textNoDashboard'>Notre dashboard mobile est en cours de développement, veuillez vous connecter sur un ordinateur.</p>
                        <Link to='/' className="backToHomeNoDashboard">
                            Revenir à l'accueil
                        </Link>
                        <img className='logoNoDashboard' src={Logo} />
                    </div>
                </div>
            }
        </>
    );
}

export default Dashboard;
