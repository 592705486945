import React, { useState, useEffect } from 'react';
import styles from './File.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import { getImageComponent } from '../../../services/storage/storageService';
import { getFileIcon, getFileExtension, formatAncre } from '../../../utils';
import { Download } from "lucide-react"

const FileComponent = (props) => {
    const { hobspace } = useHobspace();
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);

    useEffect(() => {
        const fetchMedia = async () => {
            if (props.id) {
                try {
                    // Récupération de l'URL du fichier depuis Firestore
                    const url = await getImageComponent(props.email, 'File', props.id);

                    // Téléchargement du fichier en tant que Blob
                    const response = await fetch(url);
                    const blob = await response.blob();

                    // Transformation du Blob en objet File et création de l'URL téléchargeable
                    const fileObject = new File([blob], `${formatAncre(props.title)}${getFileExtension(blob)}`, { type: blob.type });
                    setFile(fileObject);
                    setFileUrl(URL.createObjectURL(fileObject)); // Crée une URL temporaire pour le téléchargement

                } catch (error) {
                    console.error('Erreur lors de la récupération du média:', error);
                    setFile(null);
                }
            }
        };

        fetchMedia();

        // Nettoyage de l'URL temporaire lors du démontage du composant
        return () => {
            if (fileUrl) URL.revokeObjectURL(fileUrl);
        };
    }, [props.id, props.email, props.title]);

    return (
        <div className={styles.File} style={{ color: hobspace.colorFont }}>
            {!file ? (
                <div className={styles.containerImageDefault}>
                    <i className="fa-solid fa-image"></i>
                </div>
            ) : (
                <img className={styles.imgTicket} style={{ backgroundColor: hobspace.colorFont }} src={getFileIcon(file)} alt="icon produit" />
            )}

            <div className={styles.infoTicket}>
                <p style={{ color: hobspace.colorFont }} className={`${styles.titleTicket} text-xl max-md:text-lg max-425:text-base max-350:text-sm line-clamp-2`}>{props.title}</p>
                {props.subtitle !== "" && <p style={{ color: hobspace.colorFont }} className={`${styles.priceTicket} text-lg max-md:text-base max-425:text-sm max-350:text-xs line-clamp-2`}>{props.subtitle}</p>}
            </div>

            {fileUrl && (
                <a href={fileUrl} download={file.name} className="flex items-center w-9 h-9 justify-center mr-7 rounded-full p-1 cursor-pointer max-425:w-7 max-425:h-7 max-425:mr-4">
                    <Download color={hobspace.color} size={36} />
                </a>
            )}
        </div>
    );
}

export default FileComponent;
