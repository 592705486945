export const links = [
    {name: "instagram" , active: false, link: "", color: "#f70791", icon: "fa-brands fa-instagram",},
    {name: "facebook" , active: false, link: "", color: "#395498", icon: "fa-brands fa-square-facebook",},
    {name: "x" , active: false, link: "", color: "#000000", icon: "fa-brands fa-x-twitter",},
    {name: "youtube" , active: false, link: "", color: "#f60002", icon: "fa-brands fa-youtube",},
    {name: "tiktok" , active: false, link: "", color: "#000000", icon: "fa-brands fa-tiktok",},
    {name: "spotify" , active: false, link: "", color: "#6ad473", icon: "fa-brands fa-spotify",},
    {name: "deezer" , active: false, link: "", color: "#a53dff", icon: "fa-brands fa-deezer",},
    {name: "itunes" , active: false, link: "", color: "#e983dc", icon: "fa-brands fa-itunes-note",},
    {name: "linkedin" , active: false, link: "", color: "#2187be", icon: "fa-brands fa-linkedin",},
    {name: "site web" , active: false, link: "", color: "#ff8500", icon: "fa-solid fa-earth-europe",},
    {name: "twitch" , active: false, link: "", color: "#9146FF", icon: "fa-brands fa-twitch",},
    {name: "qrcode" , active: false, link: "", color: "#3960FE", icon: "fa-solid fa-qrcode"},
];