import React from 'react';
import './PricingPack.css';

const PricingPack = (props) => {
  return (
    <div className='PricingPack' style={{border: props.popular ? "#ff8500 solid 2px" : "", height: props.popular ? "100%" : "400px"}}>
      <p className='titlePricingPack'>{props.title}</p>
      {props.popular &&
          <p className='mostPopular'>Le plus populaire</p>
      }
      {props.pricingDate ? (
        <div className='containerPrice'>
            <p className='pricePricingPack'>{props.priceMounth} par mois </p>
        </div>
      ):(
        <div className='containerPrice'>
            <p className='pricePricingPack reduc'>{props.priceMounth} par mois</p>
            <p className='pricePricingPack'>{props.priceYear} par an</p>
        </div>
      )}
      <button className='buttonStartPricingPack'>Commencer votre essai gratuit</button>
      {props.pack && props.pack.map((pack, index) => (
            <div className='containerItemsInPack' key={index}>
                <div className='iconButtonPack'>
                    <i style={{ color: 'green' }} className="fa-solid fa-check"></i>
                </div>
                <p className='itemPack'>{pack}</p>
            </div>
        ))}
    </div>
  );
};

export default PricingPack;
