import React, { useEffect } from 'react';
import Modal from 'react-modal';
import "./CustomModal.css";

function CustomModal({children, isOpen, onClose, bgOverlay, width, height, margin}) {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    return (
        <div style={{ width: "calc(100% - 172.69px)", position: "absolute" }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel="Exemple de modal"
                className={`CustomModal`}
                style={{
                    overlay: {
                        backgroundColor: bgOverlay || 'rgba(0, 0, 0, 0.7)', // Utilise bgOverlay si défini, sinon valeur par défaut
                        zIndex: 1000,
                    },
                    content: {
                        width: width,
                        height: height,
                        margin: margin, // Centrer le contenu si nécessaire
                    }
                }}
            >
                {children}
            </Modal>
        </div>
    );
}

export default CustomModal;
