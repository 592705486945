import React from 'react';
import styles from './Loader.module.css';

const Loader = (props) => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loader} style={{ borderTopColor: props.color }}></div>
    </div>
  );
};

export default Loader;
