import React from 'react'
import './ReseauSocial.css';
import { useUser } from '../../utils/contexts/userContext';

function ReseauSocial(props) {

    const {user} = useUser();

    return (
        <a className='ReseauSocial' href={props.link} target='_blank' rel="noreferrer">
            <div className='iconButton'>
                <i style={{color: user.colorFont}} className={props.img}></i>
            </div>
        </a>
    );
}

export default ReseauSocial;