import React, { useEffect } from 'react';
import Modal from 'react-modal';
import styles from "./CustomModal.module.css";

function CustomModal({children, isOpen, onClose}) {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);
    // pouvoir mettre un children comme ca on peut mettre ce que l'on veut dans la modal
    return (
		<div>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				contentLabel='Exemple de modal'
				className={styles.modal}
				style={{
					overlay: {
						backgroundColor: 'rgba(0, 0, 0, 0.7)',
						zIndex: 1000,
					},
				}}
			>
			{children}
				
			</Modal>
		</div>
	)
}

export default CustomModal;

