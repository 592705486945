import React from "react";

import "./GetStarted.css";
import { useUser } from "../../utils/contexts/userContext";
import {Link} from "react-router-dom";

export default function GetStarted(props) {

    const { user, loading } = useUser();

    return (
        <div className="sectionGetStarted">
            <div className="containerTextGetStarted">
                <p className="titleGetStarted">Rejoignez HOBSPACE dès aujourd'hui pour optimiser votre présence en ligne et simplifier votre gestion de liens. </p>
                <p className="subtitleGetStarted">Adoptez un outil numérique pratique, évolutif et moderne qui répond à des besoins internes ou externes.</p>
            </div>
            {!loading && user ?
                <Link to={user.subscription.type !== "" ? "/dashboard" : "/dashboard"} className='buttonStartPricingPack'>{user.subscription.type !== "" ? "Créez votre Hobs Space" : "Commencer maintenant"}</Link>
            :
                <Link to={"/dashboard"} className='buttonStartPricingPack'>Commencer maintenant</Link>
            }
        </div>
    );
}