import React from 'react'
import './LinkedinProfil.css';
import { useUser } from '../../utils/contexts/userContext';
import { useSelector } from 'react-redux';

function LinkedinProfil(props) {
    const color = useSelector((state) => state.user.colorFont);
    const backgroundColor = useSelector((state) => state.user.backgroundColor);

    const {user} = useUser();
    const images = useSelector((state) => state.user.images);
    // quand l'utilisateur aura connecté son compte faudra mettre le lien
    return (
        <div className={`LinkedinProfil`}>
            <img className={`imgProfil`} src={images} alt='profile linkedin' />
            <div className={`containerInfoPerso`}>
                <p className={`titleProfil`} style={{color: color}}>{user.lastname + " " + user.firstname}</p>
                <p className={`singerProfil`} style={{color: color}}>{props.description !== "" ? props.description : "Décrivez votre poste/activité que vous pratiquez."}</p>
            </div>
            <div className={`linkPlateform`} style={{backgroundColor: backgroundColor}}>
                <a className={`linkImgPlateform`} href={""} target="_blank" rel="noreferrer">
                    <div className='iconButton'>
                        <i style={{color: color, fontSize: "11px"}} className={"fa-solid fa-plus"}></i>
                    </div>
                    <p style={{color: color}}>Suivre</p>
                </a>
                <div className={`lineProfil`} style={{backgroundColor: color}}></div>
                <a className={`linkImgPlateform textLRIcon`} href={""} target='_blank' rel="noreferrer">
                <div className='iconButton'>
                    <i style={{color: color, fontSize: "11px"}} className={"fa-regular fa-message"}></i>
                </div>
                    <p style={{color: color}}>Envoyer un message</p>
                </a>
            </div>
        </div>

    );
}

export default LinkedinProfil;