import React, {useState, useEffect} from 'react'
import './Home.css';
import NavigationHome from '../../Layouts/Navigation/NavigationHome/NavigationHome';
import { Link } from "react-router-dom";
import Footer from '../../Layouts/Footer/Footer';
import Logo from "../../assets/logo.png";
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';
import GetStarted from '../../Layouts/GetStarted/GetStarted';
import { listenToAuthState } from '../../services/auth/authService';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from "../../utils/contexts/userContext";
import FAQ from "../../Layouts/FAQ/FAQ";
import Img from "../../assets/img.png";

function Home() {

    const dispatch = useDispatch();
    const email = useSelector((state) => state.user.email);
    const { user, loading } = useUser();
    const image = useSelector((state) => state.user.images);

    useEffect(() => {
        listenToAuthState(dispatch, image);
    }, []);
 
    return (
        <div className='Home'>
            <TitleTabNavigator title={"Accueil"}/>
            <NavigationHome />
            <div className='w-full flex items-center justify-center' style={{backgroundColor: "black"}}>
                <img src={Logo} className='rounded-3xl object-contain object-center w-3/5' style={{height: "94vh", backgroundColor: "black"}}/>
            </div>
            {/* <div className='sectionHome1'>
                <div className='containerTitleHome'>
                    <h1 className='titleHome'>Partagez à votre réseau tout ce que vous faites grâce à un lien unique avec HOBS SPACE </h1>
                    <p className='subtitleHome'>HOBSPACE vous offre la possibilité de partager instantanément votre identité professionnelle et celle de votre entreprise avec n'importe qui, où que vous soyez. </p>
                </div>
                <img src={Img} className='containerImagesHome'/>
            </div>
            <div id="adopterHobspace" className='container-presentation-top' >
                <div className='presentation-top-left'>
                    <img className="presentation-img" src={Logo} alt={"presentation"} data-aos="fade-up" data-aos-duration="800" />
                    <h1 className='presentation-title' data-aos="fade-up" data-aos-duration="800">Adopter HOBSPACE</h1>
                    <p className='presentation-text' data-aos="fade-up" data-aos-duration="800">Nous facilitons l'expansion de votre réseau professionnel en vous permettant de partager un lien unique regroupant toutes vos informations clés.</p>
                </div>
                <div className='presentation-top-right'>
                    <div className='div-top-right'>
                        <p className='presentation-text' data-aos="fade-up" data-aos-duration="800">Souvent, vous avez une multitude de contenus numériques éparpillés sur le web : sites internet, réseaux sociaux, références de projets, vidéos, offres d’emploi, portfolios, podcasts, boutiques en ligne, formulaires de contact, webinaires, démonstrations de produits, etc. </p>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="800" className='presentation-ligne' ></div>
                    <div className='div-top-right'>
                        <p className='presentation-text' data-aos="fade-up" data-aos-duration="800">Ces ressources sont généralement disséminées à divers endroits sur Internet. Grâce à HOBSPACE, entrez dans une nouvelle ère où un lien unique suffit pour regrouper tous vos contenus essentiels et partager vos contacts. </p>
                    </div>
                    <div className='presentation-ligne' data-aos="fade-up" data-aos-duration="800" ></div>
                    <div className='div-top-right'>
                        <p className='presentation-text' data-aos="fade-up" data-aos-duration="800">Vous pouvez ensuite diffuser ce lien unique via un QR code ou en l'intégrant à votre signature d'email. </p>
                    </div>
                </div>
            </div>
            <div className='sectionHome1'>
                <div className='containerTitleHome'>
                <p className='subtitleHome'>Notre solution se présente sous la forme d'un tableau de bord simple et intuitif, ne nécessitant aucune compétence technique, et vous permettant de personnaliser votre page selon vos préférences</p>
                <p className='subtitleHome'>Connectez et partagez facilement toutes vos informations professionnelles, offrant ainsi à votre réseau un accès instantané à vos coordonnées et ressources essentielles. </p>
                <p className='subtitleHome'>Nous avons pour mission de vous aider à accroître votre visibilité et à maximiser votre potentiel commercial.</p>
                </div>
                <img src={Img} className='containerImagesHome'/>
            </div>
            <div className='sectionHome2'>
                <div className='containerDepartment'>
                    <p className='titleDepartment'>PÔLE COMMERCIAL</p>
                    <p className='subtitleDepartment'>Un gain de temps + amélioration de l’expérience client </p>
                    <div className='containerListDartDepartment'>
                        <p className='dartDepartment'><span></span>Communiquer simplement et rapidement leurs coordonnées </p>
                        <p className='dartDepartment'><span></span>Communiquer les informations essentielles à leurs prospects et clients </p>
                        <p className='dartDepartment'><span></span>Un lien utilisable sur tous les canaux de communication (Linkedin, Instagram…)</p>
                        <p className='dartDepartment'><span></span>Votre Hobs Space devient votre carte de visite virtuelle grâce à l’option qui vous permet de partager de vos coordonnées </p>
                        <p className='dartDepartment'><span></span>Suivi des performances des liens pour mesurer l’efficacité d’une campagne</p>
                    </div>
                </div>
                <div className='containerDepartment'>
                    <p className='titleDepartment'>PÔLE COMMUNICATION & MARKETING</p>
                    <p className='subtitleDepartment'>Une amélioration de l'expérience client + un excellent outil pour analyser ses performances </p>
                    <div className='containerListDartDepartment'>
                        <p className='dartDepartment'><span></span>Améliorer l’accès aux informations sur les réseaux sociaux</p>
                        <p className='dartDepartment'><span></span>Ajouter tous les liens utiles et pertinents pour les prospects à un seul endroit </p>
                        <p className='dartDepartment'><span></span>Adaptez l’apparence de votre interface pour qu’il reflète votre identité de marque. </p>
                        <p className='dartDepartment'><span></span>Suivi de campagnes marketing (publicités…) et des performances grâce aux statistiques disponibles (nb de clics, source du trafic…) </p>
                    </div>
                </div>
                <div className='containerDepartment'>
                    <p className='titleDepartment'>PÔLE RESSOURCE HUMAINE </p>
                    <p className='subtitleDepartment'>Communication interne facilité + renforcer sentiment d’appartenance </p>
                    <div className='containerListDartDepartment'>
                        <p className='dartDepartment'><span></span>Regrouper les liens utiles pour les employés (offres d’emploi internes, formation en ligne, demande de congés…) </p>
                        <p className='dartDepartment'><span></span>Faciliter la communication en interne (transmission info importantes, MAJ politiques…)</p>
                        <p className='dartDepartment'><span></span>Partage de contenus et d’événements (ITW, soirée, séminaire…) mettant en avant la culture de l’entreprise et renforçant l’engagement des employés </p>
                    </div>
                </div>
                <div className='containerDepartment'>
                    <p className='titleDepartment'>PÔLE FINANCE</p>
                    <p className='subtitleDepartment'>Centralisation des Ressources + accessibilité simplifiée </p>
                    <div className='containerListDartDepartment'>
                        <p className='dartDepartment'><span></span>Regroupez tous les liens importants vers des rapports financiers, des analyses de marché, des documents de conformité, et des outils de gestion en un seul endroit. </p>
                        <p className='dartDepartment'><span></span>Permettez aux membres de votre équipe et aux parties prenantes externes d'accéder facilement à toutes les ressources nécessaires via un lien unique. </p>
                        <p className='dartDepartment'><span></span>Partagez des mises à jour régulières, des politiques financières et des guides pratiques. </p>
                    </div>
                </div>
            </div>
            <GetStarted/> */}
            {/* <div className="vector3"></div> */}
            {/* {!loading && user ?
                // <Link to={user.subscription.type !== "" ? "/dashboard" : "/pricing"} className='buttonStartPricingPack'>{user.subscription.type !== "" ? "Créez votre Hobs Space" : "Commencer gratuitement"}</Link>
                <Link to={user ? "/dashboard" : "/pricing"} className='buttonStartPricingPack'>{user ? "Créez votre Hobs Space" : "Commencer gratuitement"}</Link>
                :
                <Link to={"/pricing"} className='buttonStartPricingPack'>Commencer gratuitement</Link>
            } 
            
            <FAQ />
            <Footer/>*/}
         </div>
    );
}

export default Home;