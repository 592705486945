import React from 'react'
import './LinkConcert.css';
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../utils';

function LinkConcert(props) {
    const color = useSelector((state) => state.user.colorFont);
    const backgroundColor = useSelector((state) => state.user.backgroundColor);
 
    return (
        
        <a className={`LinkConcert`} style={{ border: `${backgroundColor} 1px solid` }} href={props.type ? props.link : null} rel="noreferrer" target='_blank'>
            <div className={`containerInfoConcert`}>
                <p className={`event`} style={{ color: color}}>{props.title}</p>
                <p className={`where`} style={{ color: hexToRgb(color, 0.6)}}>{props.where}</p>
                {props.type ? (
                    <a className={`ticketOk`} style={{ color: color, borderColor: color }} href={""} rel="noreferrer" target='_blank'>Tickets</a>
                ) : (
                    <p className={`ticketSoldout`} style={{ color: color, borderColor: color }}>Sold out</p>
                )}
            </div>
            <div className={`separationTicket`}>
                <div className={`bulle top`} style={{ backgroundColor: backgroundColor }}></div>
                <div className={`bulles`}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
                        <div key={index} className={`bul`} style={{ backgroundColor: backgroundColor }}></div>
                    ))}
                </div>
                <div className={`bulle bottom`} style={{ backgroundColor: backgroundColor }}></div>
            </div>
            <div className={`dateConcert`}>
                <div className={`containerDateConcert`} style={{border: `1px solid ${hexToRgb(color, 0.6)}`}}>
                    <p className={`mounth`} style={{ color: hexToRgb(color, 0.6)}}>{props.mounth}</p>
                    <p className={`day`} style={{ color: color}}>{props.day}</p>
                    <p className={`year`} style={{ color: hexToRgb(color, 0.6)}}>{props.year}</p>
                </div>
            </div>
        </a>
    );
}

export default LinkConcert;