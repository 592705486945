import React, {useState, useEffect} from 'react'
import './Partners.css';
import NavigationHome from '../../Layouts/Navigation/NavigationHome/NavigationHome';
import TitleTabNavigator from '../../components/TitleTabNavigator/TitleTabNavigator';

function Partners() {

    const bgdContainer = "#ffffff";
    const colorFont = "255, 255, 255";

    useEffect(() => {
       
    }, []);
 
    return (
        <div className='Partners' style={{background: bgdContainer}}>
            <TitleTabNavigator title={"Partenaires"}/>
            <NavigationHome color={colorFont} bgd={bgdContainer} />
            <div className='containerTitlePartners'>
                <h1 className='titlePartners'>Partners</h1>
            </div>
         </div>
    );
}

export default Partners;