import React from 'react';
import styles from "./AncreMenu.module.css";
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import { Link } from "react-router-dom";
import { formatAncre } from '../../../utils';


function AncreMenu(props) {
    const { hobspace } = useHobspace();
    return (
        <Link className={styles.jsAnchorLink} to={`#${formatAncre(props.ancre)}`} style={{ color: hobspace.colorFont }}>
            {props.name}
        </Link>
    );
}

export default AncreMenu;
