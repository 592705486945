import React from 'react'
import styles from './Logo.module.css';
import ImageProfile from '../ImageProfile/ImageProfile';

function Logo(props) {

    const hobspace = props.hobspace;
    const component = hobspace.findPageByName(props.componentId);
 
    return (
        <div className={styles.Logo}>
            <div className={styles.containerCover}>
                <ImageProfile img={props.image} imgFullCover={false} format={component.vertical} />
            </div>
            {component.logo ? 
                <img className={styles.imgLogo} src={""} alt="logo personnalité" />
                :
                <>
                <h1 className={styles.titleProfile} style={{color: hobspace.colorFont}}>{hobspace.firstname} {hobspace.lastname}</h1>
                <p className={styles.subtitleProfile} style={{color: hobspace.colorFont}}>{hobspace.description}</p>
                </>
            }
            
        </div>
    );
}

export default Logo;