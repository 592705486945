import React, {useState, useEffect} from "react";
import './App.css';
import { Routes, Route } from "react-router-dom";
import Dashboard from "./views/Dashboard/DashBoard";
import { Provider } from "react-redux";
import { store } from "./services/store/store";
import Home from "./views/Home/Home";
import Pricing from "./views/Pricing/Pricing";
import Support from "./views/Support/Support";
import Contact from "./views/Contact/Contact";
import Login from "./views/Login/Login";
import SignUp from "./views/SignUp/SignUp";
import Partners from "./views/Partners/Partners";
import config from './config';
import { UserProvider } from "./utils/contexts/userContext";
import ScrollToAnchor from "./utils/ScrollToAncor";
import { HobspaceProvider } from "./utils/contexts/hobspaceContext";
import Hobspace from "./hobs-space/Views/Home";
import { getAllHobsSpace } from "./services/storage/storageHobspaceService";

// Ne pas oublier d'enlever la route namespace et de la mettre à la racine pour que se soit visible sur gh-pages
// deploy gh-pages lancer la commande npm run deploy

function App() {
  const [hobspacesID, setHobspacesID] = useState(null);

  const getHobspacesID = async () => {
      const hobspaces = await getAllHobsSpace();
      setHobspacesID(hobspaces);
  };

  useEffect(() => {
      getHobspacesID();
  }, []);

    return (
      <Provider store={store}>
        <UserProvider>
          <div className="App">
              <ScrollToAnchor />
              <Routes>
                    <Route path={`/`} element={ <Home /> } />
                    <Route path={`/dashboard`} element={ <Dashboard /> } />
                    <Route path={`/pricing`} element={ <Pricing /> } />
                    <Route path={`/partners`} element={ <Partners /> } />
                    <Route path={`/support`} element={ <Support /> } />
                    <Route path={`/contact`} element={ <Contact /> } />
                    <Route path={`/login`} element={ <Login /> } />
                    <Route path={`/signup`} element={ <SignUp /> } />
                    {hobspacesID && hobspacesID.map((hobspace, index) => {
                      return (
                          <Route
                            key={index}
                            path={`/${hobspace.id}`}
                            element={
                              <HobspaceProvider>
                                <Hobspace hobspaceId={hobspace.id} />
                              </HobspaceProvider>
                            }
                          />
                      );
                    })}
              </Routes>
          </div>
        </UserProvider>
      </Provider>
  );
}

export default App;
