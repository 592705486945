import React, { useState, useEffect } from 'react'
import './Gallery.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import Media from '../Media/Media';
import { useUser } from '../../utils/contexts/userContext';

function Gallery(props) {
    const {user} = useUser();
    const [components, setComponents] = useState([]);

    useEffect(() => {
        const components = props.components.map((c, index) => (
            <Media key={index} id={c.id} title={c.title} description={c.description} link={c.link} type={c.type} />
        ));
        setComponents(components);
    }, [props.components]); 
 
    return (
        <div className='Gallery'>
            {props.title &&
                <Title id={props.ancre} color={user.colorFont} title={props.title} marginBottom={true} />
            }
            {components && 
                <Slider components={components} />   
            }
        </div>
    );
}

export default Gallery;