import React from 'react'
import "./ButtonCardAdd.css";

function ButtonCardAddSocial(props) {
 
    return (
        <button onClick={props.active ? () => {} : props.onClick} className={`${props.active ? "button-border-active" : ""} relative flex flex-row w-auto h-auto justify-start items-center gap-2 bg-white drop-shadow-md px-4 py-2 rounded-full cursor-pointer hover:-translate-y-2 transition ease-in-out duration-300`}>
            <img src={props.img} className='h-5 w-5 object-center object-contain' />
            <p className='text-left text-base font-medium capitalize'>{props.title}</p>
        </button>
    );
}

export default ButtonCardAddSocial;