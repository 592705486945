import React, {useState} from 'react'
import logo from "../../assets/logo.png";
import {Link} from "react-router-dom";
import linkedin from "../../assets/linkedin.png";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/insta.png";
import twitter from "../../assets/x.png";
import { useUser } from "../../utils/contexts/userContext";
import localisation from "../../assets/localisation.png";
import contact from "../../assets/mail.png";

import './Footer.css';

function Footer() {

    const { user, loading } = useUser();

    return (
        <section className='Footer'>
            <div className='sectionFooter'>
                <div className='sectionFooterTop'>
                    <div className='containerFooter'>
                        <p className='containerFooterTitle'>Hob's Space</p>
                        <div className='containerFooterLink'>
                            <Link to={""} className='linkFooter' >S'inscrire</Link>
                            <Link to={""} className='linkFooter' >Dashboard</Link>
                            <Link to={""} className='linkFooter' >Tarif</Link>
                            <Link to={""} className='linkFooter' >Se connecter</Link>
                        </div>
                    </div>
                    <div className='containerFooter'>
                        <p className='containerFooterTitle'>Ressources</p>
                        <div className='containerFooterLink'>
                            <Link to={""} className='linkFooter' >Contact</Link>
                            <Link to={""} className='linkFooter' >Support</Link>
                        </div>
                    </div>
                    <div className='containerFooter'>
                        <p className='containerFooterTitle'>Confiance et juridique</p>
                        <div className='containerFooterLink'>
                            <Link to={"/terms"} className='linkFooter' >Termes & Conditions</Link>
                            <Link to={""} className='linkFooter' >Avis de confidentialité</Link>
                            <Link to={""} className='linkFooter' >Avis sur les cookies</Link>
                            <Link to={""} className='linkFooter' >Préférences en matière de cookies</Link>
                        </div>
                    </div>
                </div>
                {/* <div className='sectionFooterBottom'>
                    <div className='containerCopyRight'>
                        {!loading && user ?
                            <Link to={user.subscription.type !== "" ? "/dashboard" : "/pricing"} className='buttonStartPricingPack'>{user.subscription.type !== "" ? "Créez votre Hobs Space" : "Commencer votre essai gratuit"}</Link>
                        :
                            <Link to={"/pricing"} className='buttonStartPricingPack'>Commencer votre essai gratuit</Link>
                        }
                        </div>
                    <div className='containerReseaux'>
                        <Link to={""} className='linkFooterReseau' ><img className='logoReseauFooter' src={instagram} alt="logo réseau instagram" /></Link>
                        <Link to={""} className='linkFooterReseau' ><img className='logoReseauFooter invert' src={twitter} alt="logo réseau twitter" /></Link>
                        <Link to={""} className='linkFooterReseau' ><img className='logoReseauFooter' src={facebook} alt="logo réseau facebook" /></Link>
                    </div>
                </div> */}
            </div>
            <div className='containerLogoFooter'>
                <img src={logo} className='LogoFooter' alt='logo' />
            </div>
            <div className='ligneFooter'></div>
                <div className='containerCopyRight'>
                    <p className='copyRight'>© 2024 Hob's Space</p>
                </div>
        </section>
    );
}

export default Footer;