import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import { useSelector } from 'react-redux';
import { getImageComponent } from '../../services/storage/storageService';
import CustomModalPreview from '../Modal/CustomModal/CustomModalPreview';
import { useUser } from '../../utils/contexts/userContext';

function Media(props) {
    const {user} = useUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [url, setUrl] = useState("");
    const [image, setImage] = useState("");
    const email = useSelector((state) => state.user.email);
    const color = useSelector((state) => state.user.colorFont);

    useEffect(() => {
        console.log(props);
        const fetchMedia = async () => {
            if (props.id) {
                try {
                    const mediaUrl = await getImageComponent(email, 'Media', props.id);
                    if (mediaUrl) {
                        // Vérifier si c'est une image ou une vidéo
                        if (props.type === "video") {
                            getVideoThumbnail(mediaUrl);
                            setUrl(mediaUrl);
                        } else if (props.type === "image") {
                            setImage(mediaUrl); 
                        }
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des médias :", error);
                    setImage(null);
                }
            }
        };

        fetchMedia();
    }, [props.id, user.page]);

    const getVideoThumbnail = (videoUrl) => {
        const video = document.createElement('video');
        video.crossOrigin = "anonymous";
        video.src = videoUrl;
        video.currentTime = 1;
    
        video.onloadeddata = () => {
            try {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                const thumbnail = canvas.toDataURL('image/png');
                setImage(thumbnail);
            } catch (error) {
                console.error("Erreur lors de la création de la miniature vidéo :", error);
            }
        };
    
        video.onerror = () => {
            console.error("Erreur lors du chargement de la vidéo.");
        };
    };  
    

    return (
        <>
            {/* Affichage de l'image ou vidéo en aperçu */}
            <div className="cursor-pointer" onClick={() => setIsModalOpen(true)}>
                {image ? (
                    <img src={image} alt="Media thumbnail" className="w-32 h-32 object-cover rounded-xl" />
                ) : (
                    <div className='w-32 h-32 object-cover rounded-xl flex items-center justify-center overflow-hidden'>
                        <i className={"fa-solid fa-image"} style={{color: color, fontSize: "150px"}}></i>
                    </div>
                )}
            </div>

            <CustomModalPreview isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <button className="absolute top-8 right-8 text-gray-600 hover:text-gray-800" onClick={() => setIsModalOpen(false)}>
                    <i style={{color: "#FFF", fontSize: "24px"}} className="fa-solid fa-times"></i>
                </button>
                <div className="flex items-center justify-center z-50 w-full absolute">
                    <div className="p-4 rounded-lg max-w-xl mx-auto relative">
                        
                        {props.type === 'image' ? (
                            image ? 
                            <img src={image} alt="Full-size media" className="w-52 h-52 object-cover object-center rounded-xl mb-4 " />
                            :
                            <div className='w-32 h-32 object-cover rounded-xl flex items-center justify-center overflow-hidden'>
                                <i className={"fa-solid fa-image"} style={{color: color, fontSize: "150px"}}></i>
                            </div>
                        ) : (
                            url ? 
                            <video src={url} className="w-auto h-52 rounded-xl mb-4" controls />
                            :
                            <div className='w-32 h-32 object-cover rounded-xl flex items-center justify-center overflow-hidden'>
                                <i className={"fa-solid fa-image"} style={{color: color, fontSize: "150px"}}></i>
                            </div>
                        )}
                        {props.description && (
                            <p className="text-white w-full text-base text-center mb-2" >{props.description}</p>
                        )}
                        {props.link && (
                            <a href={props.link} target="_blank" rel="noopener noreferrer" className="underline text-white" >
                                Voir plus <i style={{color: "#FFF", fontSize: "18px", marginLeft: "5px"}} className="fa-solid fa-arrow-up-right-from-square"></i>
                            </a>
                        )}
                    </div>
                </div>
            </CustomModalPreview>
        </>
    );
}

export default Media;
