import React from 'react'
import styles from './LinkedinProfil.module.css';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';
import Linkedin from "../../../assets/linkedin.png";

function LinkedinProfil(props) {

    const {hobspace} = useHobspace();
    // quand l'utilisateur aura connecté son compte faudra mettre le lien
    return (
        <div className={styles.LinkedinProfil}>
            <img className={styles.imgProfil} src={props.image} alt='profile linkedin' />
            <div className={styles.containerInfoPerso}>
                <p className={`${styles.titleProfil} text-xl max-md:text-lg max-425:text-base max-350:text-sm`} style={{color: hobspace.colorFont}}>{hobspace.lastname + " " + hobspace.firstname}</p>
                <p className={`${styles.singerProfil} text-lg max-md:text-base max-425:text-sm max-350:text-xs`} style={{color: hobspace.colorFont}}>{props.description}</p>
            </div>
            <div className={styles.linkPlateform} style={{backgroundColor: hobspace.backgroundColor}}>
                {/* <a className={`linkImgPlateform`} href={""} target="_blank" rel="noreferrer">
                    <div className='iconButton'>
                        <i style={{color: user.colorFont, fontSize: "20px"}} className={"fa-solid fa-plus"}></i>
                    </div>
                    <p className="textLinkedinProfil" style={{color: user.colorFont}}>Suivre</p>
                </a>
                <div className={`lineProfil`}></div> */}
                <a className={styles.linkImgPlateform} href={""} target='_blank' rel="noreferrer">
                {/* <div className='iconButton'>
                    <i style={{color: user.colorFont, fontSize: "20px", top: "3px", position: "relative"}} className={"fa-regular fa-message"}></i>
                </div> */}
                    <div className={styles.linkImg}>
                        <i style={{color: hobspace.colorFont}} className={"fa-brands fa-linkedin"}></i>
                    </div>
                    <p className={`${styles.textLinkedinProfil} text-lg max-md:text-base max-425:text-sm max-350:text-xs`} style={{color: hobspace.colorFont}}>Découvrez mon Linkedin</p>
                </a>
            </div>
        </div>

    );
}

export default LinkedinProfil;