import { db } from '../firebase/firebase.config';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import User from "./user";
import Hobspace from './hopspace';

export function getPathFromCurrentUrl() {
    // Récupère l'URL actuelle depuis l'objet window.location
    const currentUrl = window.location.href;

    // Parse l'URL actuelle
    try {
        const parsedUrl = new URL(currentUrl);

        // Récupère le chemin (path) après le domaine
        const path = parsedUrl.pathname;
        //http://localhost:3000/dalzx
        // Retire le slash initial (/) si nécessaire et vérifie que le domaine est correct
        //if (parsedUrl.hostname === 'hobs-space') {
        if (parsedUrl.hostname === 'localhost') {
            return path.startsWith('/') ? path.substring(1) : path;
        } else {
            console.error("L'URL ne correspond pas au domaine hobs-space.");
            return null;
        }
    } catch (error) {
        console.error("Erreur lors de l'analyse de l'URL:", error);
        return null;
    }
}

export const getEmailUser = async (hobspace) => {
    try {
        const userDocRef = doc(db, 'hobs-space', hobspace);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
            return userDocSnap.data().email;
        } else {
            console.log("Aucun email trouvée.");
        }
    
      } catch (error) {
        console.error("Erreur lors de la récupération ou de la création de l'utilisateur:", error);
      }
};

export const getAllHobsSpace = async () => {
    try {
        const collectionRef = collection(db, 'hobs-space'); // Référence à la collection
        const querySnapshot = await getDocs(collectionRef); // Récupère tous les documents

        const allHobsSpace = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Mappe les documents pour inclure les données et leur ID
        return allHobsSpace; // Retourne la liste des documents
    } catch (error) {
        console.error("Erreur lors de la récupération des documents de hobs-space:", error);
    }
};

export const getHobspaceInfo = async (hobspace, setHobspace) => {
    // Réinitialisation de l'utilisateur
    setHobspace(null); // ici tu passes `null` pour réinitialiser l'utilisateur

    try {
        const email = await getEmailUser(hobspace);
        //dispatch(setEmail(email));
        const userDocRef = doc(db, 'users', email);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userInstance = Hobspace.fromFirestore(userData);
          console.log("Données de l'utilisateur récupérées");

          // Mets à jour l'utilisateur avec les nouvelles données
          setHobspace(userInstance);
        } else {
          console.log("Aucune donnée d'utilisateur trouvée.");
          setHobspace(null); // Si aucun utilisateur trouvé, réinitialise à `null`
        }
    } catch (error) {
        console.error("Erreur lors de la récupération ou de la création de l'utilisateur:", error);
        setHobspace(null); // En cas d'erreur, réinitialise aussi à `null`
    }
};
