import React from 'react'
import styles from './Text.module.css';
import Title from '../Title/Title';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function Text(props) {
    const {hobspace} = useHobspace();

 
    return (
        <div className={`${styles.Text} flex flex-col items-start justify-center gap-3`}>
            {props.title &&
                <Title id={props.ancre} color={hobspace.colorFont} title={props.title} marginBottom={true} />
            }
            <p className='text-justify text-base font-normal' style={{color: hobspace.colorFont}}>
                {props.description}
            </p>
        </div>
    );
}

export default Text;