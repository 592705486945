import React from 'react'
import styles from './Menu.module.css';
import AncreMenu from '../AncreMenu/AncreMenu';

function Menu(props) {

    return (
        <div className={styles.Menu}>
            {props.menu ? props.menu.map((title, index) =>
                <AncreMenu key={index} ancre={title} name={title} />
            )
            :
            null
            }
        </div>
    );
}

export default Menu;