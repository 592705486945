import React, { useEffect, useState } from 'react';
import './ButtonSocialNetwork.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { useUser } from "../../utils/contexts/userContext";
import InputFieldText from '../InputField/InputFieldText';

function ButtonSocialNetwork(props) {
    const { user } = useUser();
    const [link, setLink] = useState("");
    const [edit, setEdit] = useState(false); 
    const [visibility, setVisibility] = useState(true);    

    const handleChangeLink = (newValue) => {
        console.log(newValue);
        setLink(newValue);
    };

    const updateLinkComponent = (id) => { // Renommé fonction pour plus de clarté
        if (link !== "") {
            console.log(link);
            user.updateLinkByName(id, link);
        }
        setEdit(false);
    };

    useEffect(() => {
        if(props.link) {
            setLink(props.link.link);
        }
    }, []);

    return (
        <div className={`flex flex-row w-full py-3`} style={{color: user.colorFont}}>
            <div className='flex flex-col w-full'>
                <div className='flex flex-row w-full gap-2 items-center justify-start px-2'>
                    <img src={props.renderLinkIcon(props.link.id)} className='h-5 w-5 object-contain object-center' />
                    <p className='capitalize text-base text-left text-black font-medium' >{props.link.id}</p> 
                </div>    
                <div className={"px-2"}>
                    {edit ? (
                        <InputFieldText 
                            value={link} 
                            maxLength={2048}
                            onChange={(e) => handleChangeLink(e.target.value)} 
                            placeholder={link} 
                            title={""} 
                            type={true}
                            onBlur={() => updateLinkComponent(props.link.id)} // Appel de la nouvelle fonction renommée
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    updateLinkComponent(props.link.id);
                                }
                            }}
                        />
                    ) : (
                        <p className={"linkOverflow text-sm w-full text-left truncate text-black"}>{link}</p>
                    )}
                </div>   
            </div>
            <div className='flex flex-row gap-2'>
                <button className='buttonDragAndDrop' onClick={() => setVisibility(!visibility)}>
                    {visibility ? (
                        <i className="fa-solid fa-eye" style={{ color: "#000" }}></i>
                    ) : (
                        <i className="fa-solid fa-eye-slash" style={{ color: "#000" }}></i>
                    )}
                </button>
                {props.link.id !== "qrcode" &&
                    <button className='buttonDragAndDrop' onClick={() => setEdit(!edit)}>
                        <i className="fa-solid fa-pencil" style={{ color: "#000" }}></i>
                    </button>
                }
                
                <button className='buttonDragAndDrop' onClick={() => props.deleteLink(props.link.id)}>
                    <i className="fa-solid fa-trash" style={{ color: "#000" }}></i>
                </button>
            </div> 
        </div>
    );
}

export default ButtonSocialNetwork;
