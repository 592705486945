import React from 'react';
import './MobilePreview.css';
import { useUser } from "../../utils/contexts/userContext";

const MobilePreview = ({ children, bgd }) => {

    const { user, loading } = useUser();

    return (
        <div id="preview" className="fixed h-[90vh] w-6/6 mobile-preview">
        <div className=" mobile-content"  style={{backgroundColor: bgd}}>
            {children}
        </div>
        <div className='mobile-stripe top'></div>
        <div className='mobile-stripe bottom'></div>
        </div>
    );
};

export default MobilePreview;
