import React, { useState, useEffect } from 'react'
import styles from './Event.module.css';
import Title from '../Title/Title';
import Slider from '../Slider/Slider';
import LinkConcert from '../LinkConcert/LinkConcert';
import { useHobspace } from '../../../utils/contexts/hobspaceContext';

function Event(props) {
    const {hobspace} = useHobspace();
    const [components, setComponents] = useState([]);

    useEffect(() => {
        const components = props.components.map((c, index) => (
            <LinkConcert key={index} id={c.id} type={c.type} title={c.title} where={c.where} day={c.day} mounth={c.mounth} year={c.year} link={c.link} />
        ));
        setComponents(components);
    }, [props.components]); 
 
    return (
        <div className={styles.Event}>
            {props.title &&
                <Title id={props.ancre} color={hobspace.colorFont} title={props.title} marginBottom={true} />
            }
            
            {components.length > 0 && components.length < 4 && components.map((component, index) => (
                <React.Fragment key={index}>
                    <div className='flex flex-col items-center justify-center gap-6 w-full mt-6 max-350:gap-4 max-350:mt-4'>
                        {component}
                    </div>
                </React.Fragment>
            ))}
            
            {components.length >= 4 && 
                <Slider components={components} column={true} />   
            }
        </div>
    );
}

export default Event;